#button1 {
  font-size: 15px;
  border-radius: 4px;
  background-color: #21325d;

  display: flex;
  align-items: center;
  border: none;
  padding: 8px 20px;
  color: #fff;

  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  overflow: hidden;
  transition: all 0.2s;
}
button #span1 {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

button #svg1 {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

button:hover .svg1-wrapper1 {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

button:hover #svg1 {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

button:hover #span1 {
  transform: translateX(5em);
}

button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/* ////////////////////// */
/* styles.css */

.modal1 {
  display: block; /* Ensure modal is displayed */
  position: fixed !important;
  /* z-index: 1; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with transparency */
}

.modal-content1 {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close1 {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close1:hover,
.close1:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modalcolor {
  background-color: none !important;
  border: none;
}

.rmvBG .MuiBackdrop-root {
  background-color: unset !important;
  border: none;
}

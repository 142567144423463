.qrBox {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    background-color: transparent !important;
    /* border-radius: 8px; */
    justify-content: space-evenly;
    padding: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    background-repeat: no-repeat;
    background-size: cover;
    /* align-items: flex-start; */
    /* border: 1px solid lightgray; */
}


/* .downloadHeading {
  width: 60%;
} */

.ourQr img {
    width: 150px;
    border: 2px solid #21325d;
    border-radius: 0px 0px 10px 10px;
}

.scanPoly {
    background-color: #21325d;
    height: 40px;
    border-radius: 10px;
    clip-path: polygon(0 0, 100% 0, 100% 67%, 56% 68%, 50% 100%, 45% 70%, 0 70%);
}

.scanPoly p {
    font-weight: 700;
    color: white;
    width: 100% !important;
    text-align: center;
}

.qrTextDownload {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    /* max-width: 490px; */
}

.qrTextDownload p {
    width: 60%;
    text-align: center;
}

.qrTextDownload span {
    font-weight: 700;
}

.qrTextDownload h3 {
    font-weight: 900;
    font-size: 45px;
    text-align: left;
    color: #21325d;
}


.allImgDownload {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.PlayQr {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    justify-content: space-evenly;
}

.PlayQr img {
    width: 192px;
}

.outMobileApp {
    /* background-image: url(../../images/mobileframe.jpg); */
    background-size: contain;
    height: 400px;
    width: 188px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    border-radius: 25px;
}

.outerBacksvg {
    position: relative;
}

/* .outerBacksvg::before { */
    /* position: absolute;
  content: '';
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 300px;
  border-radius: 50%;
  background-image: radial-gradient(#fff, #fff, #d90429, #d90429); */
/* } */

.firFrame {
    width: 100%;
    height: 100%;
}

.outMobileApp .appDemo {
    width: 172px;
    border-radius: 20px;
    z-index: -1;
    position: absolute;
    height: 373px;
    top: 10px;
    left: 8px !important;
}


@media (max-width: 750px) {
    .outerBacksvg {
        display: none;
    }

    .qrBox {
        background-image: unset;
        background-color: unset;
        box-shadow: unset;
    }
}
.timer {
  text-align: right !important;
  font: normal normal bold 16px/15px Quicksand !important;
  letter-spacing: 0px !important;
  color: #252525 !important;
  opacity: 1 !important;
}

.city {
  font-size: 12px !important;
  color: #707070 !important;
  text-align: right !important;
  letter-spacing: 0px !important;
  line-height: 15px !important;
  opacity: 1 !important;
}

.timer2 {
  text-align: left !important;
  font: normal normal bold 16px/15px Quicksand !important;
  letter-spacing: 0px !important;
  color: #252525 !important;
  opacity: 1 !important;
}

.city2 {
  font-size: 12px !important;
  color: #707070 !important;
  text-align: left !important;
  letter-spacing: 0px !important;
  line-height: 15px !important;
  opacity: 1 !important;
}

.duration {
  font-size: 9px !important;
  color: #707070 !important;
  text-align: center !important;
  letter-spacing: 0px !important;
  line-height: 15px !important;
  opacity: 1 !important;
}

.flightup {
  color: #00bdc4;
}

.next {
  text-align: right !important;
}

.flightContainer {
  padding: 20px;
  width: 80%;
  height: 100%;

  background: white;
}

.flightNavBar {
  width: 100%;

  margin-top: 20px;
}

.flightNavBarContainer {
  width: 80%;
  height: auto;
  margin: 20px;
}

/* popularfilter */

.space {
  height: 20px;
}

.price_content {
  box-shadow: 2px 2px 2px rgb(184, 182, 182);
  border: 1px solid rgb(220, 218, 218);
}

.Bton_filter:hover {
  background-color: #4260d8 !important;
  color: white;
}

.flight_name {
  margin-left: 20px;
  font: normal normal bold 13px/20px Quicksand !important;
  letter-spacing: 0px !important;
  color: #252525 !important;
  opacity: 1;
}

.flight_class {
  font: normal normal 10px/10px Quicksand !important;
  color: #707070 !important;
}

.flight_price {
  font: normal normal bold 20px/1px Quicksand !important;
  letter-spacing: 0px !important;
  color: #21325d !important;
  opacity: 1 !important;
  color: #21325d;
  font-size: 16px !important;
  font-family: "Montserrat";
  font-weight: 600;
  word-wrap: break-word;
}

.btn_booknow {
  width: 100% !important;
  background: #4260d8 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  color: "white" !important;
}

/* end */

/* flight detail */

.seat_left {
  color: #ff0000 !important;
  font: normal normal bold 10px Quicksand !important;
  margin-bottom: 10px;
}

.margin-pecentage {
  margin: 0px 10%;
  background-color: white;
  padding: 10px 15px !important;
  border-radius: 8px;
}

.flgihtdetailsTabBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flightdetailsTabs {
  box-shadow: none !important;
  margin: 12px 0px !important;
  padding: 10px 50px !important;
  margin: 0px 0px 12px 0px !important;
  color: #000 !important;
  font-weight: 600 !important;
  background: #fff !important;
  border-width: 3px 3px 3px 3px !important;
  border-style: solid !important;
  border-color: 3px solid #E73C34 !important;
  width: 100%;
  text-align: center;
}

.flightdetailsTabs:nth-child(1) {
  border-radius: 8px 0px 0px 8px;
  border: 3px solid #E73C34 !important;
}

.flightdetailsTabs:nth-child(3) {
  border-radius: 0px 8px 8px 0px;
  border: 3px solid #E73C34 !important;
}

.flightdetailsTabs:nth-child(2) {
  border-width: 3px 1px 3px 1px !important;
  border: 3px solid #E73C34 !important;
}

.flightdetailsTabsActive {
  background: rgba(231, 60, 52, 0.15) !important;
  border: 3px solid #E73C34 !important;
  color: #000 !important;
}

.fareFilter {
  margin-bottom: 15px;
}

.fareFilter p {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 10px !important;
}

.fareFilter > div {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.fareFilter label {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.airlinesFilter {
  margin-bottom: 15px;
}

.airlinesFilter p {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 10px !important;
}

.airlinesFilter > div {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.airlinesFilter label {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.cheapestFilter {
  margin-bottom: 15px;
}

.cheapestFilter p {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 10px !important;
}

.cheapestFilter > div {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.cheapestFilter label {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.outboundFilter {
  margin-bottom: 15px;
}

.outboundFilter p {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 10px !important;
}

.outboundFilter > div {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.outboundFilter label {
  color: black;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.flightFilterBox {
  background-color: white;
  /* border: 1px solid lightgray; */
  border-radius: 5px;
  padding: 10px;
}

.flightFilterBox .filterTitle {
  background-color: white !important;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: #071c2c;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: normal;
  transition: all 2s;
}

.flightFilterBox .filterTitle :hover {
  background-color: #071c2c;
  color: white;
  width: 100%;
}

.innerFilter > p {
  color: #b03737;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 8px 0px !important;
  line-height: normal;
}

.sidebar-title {
  font-weight: normal;
  margin-bottom: 5px;
  color: #e73c33;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar-items {
  margin-top: 20px;
}

.sidebar-label-container {
  display: block !important;
  position: relative !important;
  /* padding-left: 35px !important; */
  margin-bottom: 12px !important;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 400 !important;
}

.sidebar-label-container-outer-div label {
  font-size: normal !important;
}

.sidebar-label-container input {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

.checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 20px !important;
  width: 20px !important;
  background-color: #eee !important;
  border-radius: 50% !important;
  border: 2px solid rgb(165, 164, 164) !important;
  font-size: normal !important;
}

.checkmark .sidebar-label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.sidebar-label-container input:checked ~ .checkmark {
  background-color: #e73c33 !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-label-container input:checked ~ .checkmark:after {
  display: block;
}

.sidebar-label-container .checkmark:after {
  top: 4.4px;
  left: 4.4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.flightBoolkInternationalButton {
  width: 100px;
  border-radius: 10px;
  /* // border: "10px solid red", */
  height: 40px;
  font-size: 11px;
  margin-top: 10px;

  font-family: "Montserrat";

  background-color: #21325d;

  border: 2px solid #21325d;
  color: #fff;
}

.rangeInputPrice {
  height: 5px !important;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
  height: 11px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #21325d;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #21325d;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #21325d;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #21325d;
}

/*=============
Aesthetics 
=========================*/

.wrapperRange {
  color: #4b4949;
  /* background: #21325d; */
  /* max-width: 400px; */
  width: 100%;
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
}

.Inputrange {
  display: flex;
  align-items: center;
  /* max-width: 500px; */
  /* height: 4rem; */
  width: 100%;
  background: #fff;
  flex-direction: column;
  /* padding: 0px 10px; */
}

.price_range {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
}

.price_range {
  font-size: 12px;
}

.FlightTimingToggle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #8080800f;
  justify-content: space-between;
  height: 25px !important;
}

.FlightTimingToggle div {
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  text-align: center;
  height: 100% !important;
}

.FlightToggleActive {
  background-color: #21325d !important;
  color: white !important;
  transition: all 2s;
}

.FlightToggleInActive {
  background-color: transparent;
  color: #8d8a8a;
}

.timeContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px !important;
  margin: 5px !important;
  border: 1px solid #ccc !important;
}

.timeContainerTime {
  font-size: 12px !important;
  color: #ccc;
}

.timeContainer svg {
  fill: rgba(128, 128, 128, 0.966);
}

.timeContainerTimeActive {
  background-color: #21325d !important;
  color: #fff !important;
  transition: all 0.5s;
}

.timeContainer:hover {
  background-color: #21325d77 !important;
}

.timeContainerTimeActive svg {
  fill: white !important;
}

.stops_filter {
  margin: 4px 0px;
  color: #b03737;
  font-size: 18px;
  font-weight: 500;
}

.filter_Box_X {
  margin-top: 5px;
}

.AirelinesFilter {
  display: flex;
  margin-bottom: 5px;
  background-color: #faf7f7da;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(72, 73, 79, 0.96);
  -moz-box-shadow: 0px 0px 2px 0px rgba(72, 73, 79, 0.96);
  box-shadow: 0px 0px 2px 0px rgba(72, 73, 79, 0.96);
}

.AirelineFliterActive {
  background-color: #8080801f !important;
  border: 1px solid #21325d77 !important;
}

.AirelinesFilter:hover {
  background-color: #fff;
  border: #e73c33;
}

.AirelinesFilter:hover div {
  color: #e73c33;
}

.AirelinesFilter > div {
  padding-right: 5px;
  border-radius: 4px;
  color: rgb(0 0 0/0.85);
}

/* .sliderRange {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.sliderRange:hover {
  opacity: 1;
}

.sliderRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #21325d;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.2)!important;
  cursor: pointer;
  border-radius: 50%;
}

.sliderRange::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #04AA6D;
  cursor: pointer;
} */

.checkedSVG svg {
  fill: gray;
  transition: all 0.1s;
}

.busDepartureMain {
  /* display: flex; */
  padding: 14px 8px;
  background: #fffbfb;
  border-radius: 8px;
  margin-bottom: 10px;
}

.searchBarPackageFOrm {
  margin-bottom: 15px;
}

.PackageDepartureMain {
  /* display: flex; */
  padding: 14px 8px;
  background: #fffbfb;
  border-radius: 8px;
  margin-bottom: 10px;
}

.busDepartureMain > div {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.svgBOx {
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  background-color: white;
  border-radius: 5px;
  padding: 6px;
}

.svgBOx > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.sidebar-label-container:has(input:checked) .svgBOx {
  border: 1px solid #d90429 !important;
}

.sidebar-label-container:has(input:checked) > div .checkedSVG svg {
  fill: #d90429;
}

.sidebar-label-container:has(input:checked) > div span {
  color: #d90429;
}

.svgBOx span {
  font-size: 13px;
  display: block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svgBOx img {
  width: 30px;
  height: 30px;
  border-radius: 8px;
}

.checkedSVG {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0 !important;
}

.busDepartureMain .sidebar-label-container {
  margin-bottom: 0 !important;
}

.exceptionalFlex {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

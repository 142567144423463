/* Add CSS to your component */
.button-container {
  background: white;
  padding: 2rem;
  margin: auto;
  width: 1100px;
  height: 520px;
  border-radius: 8px;
}

.header {
  width: 942px;
  height: 84px;
  background: #22344F;
  margin: auto;
  margin-top: -75px;
  text-align: center;
  border-radius: 8px;
}

.header p {
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  padding-top: 20px;
  margin: auto;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
}

.category {
  width: 50%;
  display: flex;
  align-items: center;
  height: 50%;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
}

.category p {
  font-size: 25px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 1rem;
}

.category img {
  margin-top: 160px;
}

.menu {
  width: 371px;
  height: 434px;
  border: 1px solid #BBBBBB;
  background-color: #F1F5FF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  /* overflow: auto; Add scrollbars for overflow content */
}

.menu div {
  display: table;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #BBBBBB;
}

.menu div div {
  display: table-row;
}

.menu div div {
  display: table-cell;
  padding: 8px;
  border: 1px;
  padding: 10px;
}

/* Add styles for the first row (header row) if needed */
.menu div div:first-child {
  font-weight: bold;
  color: black;
}

/* Add styles for the table cells (menu items) */
/* .menu div div {
  } */

/* Add hover effect for better interactivity */
.menu div div:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}



/* shaan administration design */


.administrationBoxMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.administrationBoxMain .row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.administrationBoxMain .col-lg-3 {
  width: 170px;
  height: 100px;
  max-width: 250px;
  background-color: #21325d;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px solid #21325d;
  height: 150px;
  font-size: 25px;
  font-weight: 700;
  color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.administrationBoxMain .col-lg-3:hover {
  width: 170px;
  height: 100px;
  max-width: 250px;
  height: 150px;
  color: #21325d;
  background-color: white;
  border: 2px solid #21325d;
}

.administrationBoxMain .col-lg-3:nth-child(1) {
  background-color: #FFFFFF;
  box-shadow: unset;
  border: unset;
}

.administrationBoxMain p {}

/* .adminsImg {
  background-color: #fff;
} */



.administrationBoxMain-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.administrationBoxMain-new .row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.administrationBoxMain-new .col-lg-3 {
  width: 170px;
  height: 100px;
  max-width: 250px;
  background-color: rgba(231, 60, 52, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px solid rgba(231, 60, 52, 0.15);
  height: 150px;
  font-size: 25px;
  font-weight: 700;
  color: #E73C34;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.administrationBoxMain-new .col-lg-3:hover {
  width: 170px;
  height: 100px;
  max-width: 250px;
  height: 150px;
  color: #E73C34;
  background-color: white;
  border: 2px solid #E73C34;
}

.administrationBoxMain-new .col-lg-3:nth-child(1) {
  background-color: #FFFFFF;
  box-shadow: unset;
  border: unset;
}
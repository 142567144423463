@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@300;400;500;600;700;800&display=swap");

.centeredBox {
  max-width: 800px;
  /* background-color: #F1F7F8; */
  background-color: #fff;
  /* background-color: #e73c33; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 45px 20px 20px 20px;
  border-radius: 15px;
  border: 1px solid #e4e0e0;
  position: relative;
  margin: 60px 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.centered-box-top {
  position: absolute;
  /* background-color: #21325D; */
  /* background-color: #e73c33; */
  background: linear-gradient(360deg, #d90429, #d904279c);
  height: 70px;
  border-radius: 15px;
  width: 80%;
  top: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 1px 2px 20px -2px black; */
}

.centered-box-top p {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.centeredBox-content {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.border-right-dashed {
  border-right: 1px dashed #000;
  /* Adjust the color and thickness as needed */
}

.border-bottom {
  border-bottom: 1px dashed #000 !important;
}

.centeredBox-avatar {
  font-size: 50px;
  color: #21325d;
}

.centeredBox-name {
  font-size: 20px;
  font-weight: 500;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.centeredBox-content:not() {
  border-right: 1px dashed black;
}

@media only screen and (min-width: 590px) and (max-width: 680px) {
  .centeredBox-content {
    width: 161px;
  }
}

/* ////////////////////////////newcss///////////////////////////// */

.mainbox-container {
  /* background-image: url("../Images/Homepage.png"); Replace 'path/to/your/image.jpg' with the path to your image */
  background-size: cover; /* Adjusts the background image size to cover the entire container */
  background-position: center; /* Centers the background image */
  height: 100vh; /* Adjust height as needed */
  width: 100%;
  overflow: hidden !important;
  margin-top: -40px;
  padding-top: -30px !important;
}

.content-icon {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  /* width: fit-content; */
  align-items: center;
}

.icon-content {
  border-radius: 16px;
  background: rgba(253, 255, 245, 0.55);
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: row;

  padding: var(--8, 32px);
  /* justify-content: center;
  align-items: flex-start; */
  gap: 48px !important;
}

.icon-content-img-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #15325d;
  gap: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

.icon-content-img-content-img{
  height: 87px !important;
}

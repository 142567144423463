.transfer_top{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    opacity: 1;
    padding: 15px;
}
.transfer_top_detail{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    opacity: 1;
    border-radius: 10px;
    padding: 15px;
}
.info_txt{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0px;
    color: #252525;
}

.link_txt{
    text-align: left;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0px;
    color: #006FFF;
}

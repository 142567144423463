.topBoxDeparture {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.topBoxDeparture h3 {
    font-family: 'Montserrat';
    font-weight: 600;
}

.topBoxDeparture p {
    color: #21325d;
    font-weight: 500;
    font-family: 'montserrat';
}

.fixedDepartButton {
    background-color: #21325d;
    color: #fff;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 16px;
    padding: 8px 19px;
    border: 2px solid #21325d;
    border-radius: 8px;

}

.fixedDepartSelect {
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.grid_item{
    width: 75px !important;
    /* word-wrap: break-word !important; */
    border: 1px solid white;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    height: 70px;
    align-items: center;
    font-size: 14px;
    font-family: 'Montserrat';
    
    /* padding: 10px; */

}
.item1{
    background-color: #fff ;
    color: #21325d;
    border: 0.5px solid   #21325d;
}
.book_know_btn{
    width: 100%;
    height: 100%;
    background-color: #21325d;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: #fff;
    
}
.child1:nth-child(odd)>.item1{
    background-color:#7e7b7b !important;

}
.table1{
    width: 100% !important;
    overflow-x: scroll !important;
}
table{
    background-color: #21325d;
    
    overflow: scroll !important ;
    margin-top: 30px;
    

}
tr{ 
    overflow-x: scroll;
}
td{
    color: #fff;
}
.fixedForm{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 11px;
    max-width: 600px;

}
.fixedForm>label{
    display: flex;
    font-size: 16px;
    color: #21325d;
    font-family: "Montserrat";
    justify-content: space-between;
    align-items: center;
}
.fixedForm>label>input{
    display: flex;
    font-size: 16px;
    color: #21325d;
    font-family: "Montserrat";
    height: 40px;
    border: 0.5px solid #21325d;
    border-radius: 6px;
    padding: 0px 5px
}
input{
    display: flex;
    font-size: 16px;
    color: #21325d;
    font-family: "Montserrat";
    height: 40px;
   /* // border: 0.5px solid #21325d; */
    border-radius: 6px;
    padding: 0px 5px
}
select{
    display: flex;
    font-size: 16px;
    color: #21325d;
    font-family: "Montserrat";
    height: 40px;
    border: 0.5px solid #21325d;
    border-radius: 6px;
    padding: 0px 5px
}
.passenger{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.passenger >h1{
    color: #21325d;
    font-family: "Montserrat";
    font-size: 24px;
}
.fixeddGlass{
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    width: 90%;
    height: 95% !important;
    padding: 0px !important;
}
#zZindex{
    z-index: 99999 !important;
}
.fixedContainer{
    /* overflow: hidden; */
    
    height: 95%;
    overflow-y: scroll;
    padding: 10px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    align-items:center;
    justify-content:center;
    gap:20px;
    flex-direction: column !important;
    /* flex-wrap: wrap; */
}
.Fixedname{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* background-color:yellow; */
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;
    
   
       
}
.fixed_data{
    display: flex;
    gap: 15px;
    /* align-items: center; */
    /* justify-content: center; */
    flex-wrap: wrap;
    width: 100%;

    
}
.backButton{
    height: 50px !important;
    background-color: #21325d !important;
    color:#fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .backButton{
    background-color: red;
} */

.backButto-new button{
    height: 50px !important;
    border-radius: var(--rounded, 4px);
    background: rgba(231, 60, 52, 0.80) !important;
    color:#fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixedDepartButton-new{
    border-radius: var(--rounded, 4px);
    background: rgba(231, 60, 52, 0.80) !important;
    color:#fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}


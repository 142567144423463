.btn {
  box-shadow: none !important;
  /* padding: 5px 60px; */
  margin: 12px 0px;
  /* padding: 5px 60px; */
  margin: 12px 0px;
  color: #fff;
  /* background: #21325d; */
  white-space: nowrap !important;
  flex: 1;
}

.css-1gqfr5c-MuiButtonBase-root-MuiTab-root {
  box-shadow: none !important;
  margin: 12px 0px !important;
  /* padding: 5px 50px !important; */
  margin: 12px 0px !important;
  color: #6B7280 !important;
  font-weight: 600 !important;
  border-radius: 4px;
  /* background: #fffbfb !important; */
  border-width: 3px 2px 3px 3px !important;
  /* border: 3px solid #21325D !important; */
  /* border-right: 2px !important; */
  /* border-style: solid !important; */
  /* border-color: #21325d !important; */
}

.css-1gqfr5c-MuiButtonBase-root-MuiTab-root:nth-child(2) {
  border-width: 3px 0px 3px 3px !important;
}

.css-1gqfr5c-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #E73C34 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 162.5%; /* 26px */

  
}

/* .btn:hover {
  /* background-color: #21325d; */
  /* color: white; */
/* }  */

.backWall {
  border: 2px solid #d5d4d4;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.css-heg063-MuiTabs-flexContainer {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (min-width: 600px) and (max-width: 850px) {
  .css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.btn{
    white-space: nowrap;
}
  .css-1gqfr5c-MuiButtonBase-root-MuiTab-root {
    padding: 5px 32px !important;
  }

  .interchange img {
    width: 40px;
  }
}

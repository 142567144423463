/* AdminDashboard.css */
/* AdminDashboard.css */
.admin-dashboard-grid.loading {
  background-color: white;
}

.admin-dashboard-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  padding: 50px;

  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  animation: gradientAnimation 10sinfinite alternate;
  -webkit-animation: gradientAnimation 10sinfinite alternate;
}
.notification_Icon_Admin :hover{

}
.SubAdminNotificationContainer_innerDev{
  background-color: #fdfdfdcf;
  transition: all .5s ease-in-out;

  
}
.SubAdminNotificationContainer_innerDev:hover{
  background-color: white !important;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* .grid-item {
    display: flex;
    justify-content: center;
  } */
.progress-bar {
  width: calc((dashboardData[key] / maxValue) * 100%);
}

/* AdminDashboard.css */

/* ... (existing styles) ... */

.progress-bar-container {
  overflow: hidden;
  height: 8px; /* Set the height of the progress bar */
  border-radius: 4px; /* Add border-radius for a rounded appearance */
  margin-top: 30px; /* Adjust margin for spacing */
  
}

.progress-bar {
  background-color: #2196f3; /* Blue color for the progress bar */
  transition: width 0.3s ease-in-out;
  width: 0; /* Initial width is 0 */
}

.admin-dashboard-card:hover .progress-bar {
  width: 100%;
}

/* .grid-item-0 .admin-dashboard-card {
  background-color: #d2dff3;
} */
/* .grid-item-1 .admin-dashboard-card {
  background-color: rgb(255, 199, 198);
} */

/* .grid-item-2 .admin-dashboard-card {
  background-color: #d2dff3;
} */
/* .grid-item-3 .admin-dashboard-card {
  background-color: rgb(255, 199, 198);
} */
/* .grid-item-4 .admin-dashboard-card {
  background-color: #d2dff3;
} */
/* .grid-item-5 .admin-dashboard-card {
  background-color: rgb(255, 199, 198);
}
.grid-item-6 .admin-dashboard-card {
  background-color: #d2dff3;
}
.grid-item-7 .admin-dashboard-card {
  background-color: rgb(255, 199, 198);
}
.grid-item-8 .admin-dashboard-card {
  background-color: #d2dff3;
} */
.admin-dashboard-container {
  position: absolute;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
  .admin-dashboard-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    right:50%;
    transform: translate(-50%, -50%);
    z-index: 9999; 
    background-color: white;
  }
.admin-dashboard-card {
  position: relative;
  max-width: 345px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.card-action-area {
  position: relative;
}

.card-content {
  position: relative;
}

.box {
  position: absolute;
  top: 0;
  right: 0;
}

.typography-h6-0,
.typography-body2-0 {
  color: #fff;
}

.typography-h6-1,
.typography-body2-1 {
  color: #333;
}

.typography-h6 {
  font-weight: bold;
  font-size: 18px;
}

.typography-body2 {
  font-weight: 500;
  font-size: 16px;
}

.box-0 svg {
  color: #fff;
}

.box-1 svg {
  color: #333;
}

.progress-bar-container {
  overflow: hidden;
}

.progress-bar {
  background-color: #2196f3;
  transition: width 0.3s ease-in-out;
}

.admin-dashboard-card:hover .progress-bar {
  width: 100%;
}

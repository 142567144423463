.mainn-txt {
  align-items: left;
  font-family: var(--bs-body-font-family);
  font-weight: bold !important;
  font-size: 20px;
  letter-spacing: 0px;
  color: #252525;
}

.thirdd-txt {
  color: #252525;
  font-size: 14px;
  display: flex;
}

.checkk-txt {
  color: #ff8900;
  font-family: var(--bs-body-font-family);
  font-weight: bold;
  font-size: 14px;
  display: flex;
}

.continuee_btn {
  background: #006fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: white;
  opacity: 1;
}

.hotelBookNowOuter {
  padding: 5px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
}

/* review description area  */

.text-hotelName {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hotelDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #dfe6f7;
  padding: 15px;
  border-radius: 8px 8px 0px 0px;
}

.hotelDetails > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: ; */
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rmv-margin .row {
  margin-top: 0 !important;
}

.hotelDetails p {
  margin-bottom: 0 !important;
}

.hotelName {
  font-size: 18px;
  color: #21325d;
  font-weight: bold;
}

.roomDetails {
  padding: 15px;
  border-radius: 0px 0px 8px 8px;
  /* background: rgba(187, 187, 187, 0.30); */
}

.headForm {
  /* margin-left: 15px; */
  margin-bottom: 15px;
}

.padd {
  padding-left: 15px;
}

.roomDetails .title {
  font-size: 1.2rem;
  color: #21325d;
  font-weight: 700;
}

.roomDetails p {
  font-weight: 600;
}

.headText {
  padding: 15px;
}

.headText h2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.services {
  margin-left: 15px;
  border: none;
  box-shadow: none !important;
}

.services > div {
  box-shadow: none;
}

.services label {
  color: #E73C33;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.guestDetailsForm p {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.guestDetailsForm {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}

.otherDetailsData {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  /* background: #D8DFF2; */
}

.hotelNameAccord {
  /* margin-left: 15px; */
  margin-bottom: 15px;
}

.hotelNameAccord p {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cancelAccord {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.cancelAccord span {
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cancelAccord p {
  color: #000;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reviewDescriptionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
}

.reviewDescriptionButton button {
  padding: 12px 36px;
  border-radius: 8px;
  background: #21325d;
  border: 2px solid #21325d;
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s;
}

/* .reviewDescriptionButton button:hover {} */

.adultss {
  /* d-flex justify-content-center align-items-center */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 850px) {
  .mb-md-3 p {
    margin: 10px;
  }

  .adultss {
    /* d-flex justify-content-center align-items-center */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-left: 12px;
  }
}

.hotelBookNowHeader-new {
  display: flex;
  padding: 5px var(--0, 0px);
  justify-content: space-evenly;
  align-items: center;
  gap: 29px;
}

.serach-hotel-discribe-new {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.serach-hotel-discribe-new-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #071c2c;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.serach-hotel-discribe-new-content1 {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serach-hotel-discribe-new {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.hotelBookNowOuter-new {
  border-radius: 16px;
  border: 1px solid #ffe7e7 !important;
  background: #fffbfb !important;
  padding: 12px;
}

.hotelDetails-new {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #ffe7e7;
  background: #fffbfb;
  padding: 15px;
}

.hotelDetails-new > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: ; */
}


.hotelName-new {
    color: #000;
/* font-family: Montserrat; */
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }




  .headText-new {
    padding: 15px;
    border-radius: var(--rounded, 4px);
background: rgba(231, 60, 52, 0.15);
  }
  
  .headText-new h2 {
    color: #000;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .proceed-book-new-hotel button{
    display: flex;

padding: 6px;

color: var(--white, #FFF);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 25.6px */
justify-content: center;
align-items: center;
border-radius: var(--rounded, 4px);
background: #E73C34;
  }


  
  .proceed-book-new-hotel button:hover{
color: var(--white, #FFF);
background: #E73C34;
  }




  .bookingDetailsGuestHeader-new{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #E73C34;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
    background: rgba(231, 60, 52, 0.15);
    padding: 15px;
    border-radius: 6px;
  }

  .mainn-txt {
    align-items: left;
    font-family: var(--bs-body-font-family);
    font-weight: bold !important;
    font-size: 20px;
    letter-spacing: 0px;
    color: #252525;
  }
  
  .thirdd-txt {
    color: #252525;
    font-size: 14px;
    display: flex;
  }
  
  .checkk-txt {
    color: #ff8900;
    font-family: var(--bs-body-font-family);
    font-weight: bold;
    font-size: 14px;
    display: flex;
  }
  
  .continuee_btn {
    background: #006fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    color: white;
    opacity: 1;
  }
  
  .hotelBookNowOuter {
    padding: 5px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 24px -11px;
  }
  
  /* review description area  */
  
  .text-hotelName {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .hotelDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #dfe6f7;
    padding: 15px;
    border-radius: 8px 8px 0px 0px;
  }
  
  .hotelDetails > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: ; */
  }
  
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .rmv-margin .row {
    margin-top: 0 !important;
  }
  
  .hotelDetails p {
    margin-bottom: 0 !important;
  }
  
  .hotelName {
    font-size: 18px;
    color: #21325d;
    font-weight: bold;
  }
  
  .roomDetails {
    padding: 15px;
    border-radius: 0px 0px 8px 8px;
    /* background: rgba(187, 187, 187, 0.30); */
  }
  
  .headForm {
    /* margin-left: 15px; */
    margin-bottom: 15px;
  }
  
  .padd {
    padding-left: 15px;
  }
  
  .roomDetails .title {
    /* font-size: 1.2rem;
    color: #21325d;
    font-weight: 700; */
  
    color: #071c2c;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }
  
  .roomDetails p {
    font-weight: 600;
  }
  
  .headText {
    padding: 15px;
  }
  
  .headText h2 {
    color: #000;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .services {
    margin-left: 15px;
    border: none;
    box-shadow: none !important;
  }
  
  .services > div {
    box-shadow: none;
  }
  
  .services label {
    color: #e73c33;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .guestDetailsForm p {
    color: #000;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .guestDetailsForm {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .otherDetailsData {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    /* background: #D8DFF2; */
  }
  
  .hotelNameAccord {
    /* margin-left: 15px; */
    margin-bottom: 15px;
  }
  
  .hotelNameAccord p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .cancelAccord {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .cancelAccord span {
    color: #000;
  
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .cancelAccord p {
    color: #000;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .reviewDescriptionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
  }
  
  .reviewDescriptionButton button {
    padding: 12px 36px;
    border-radius: 8px;
    background: #21325d;
    border: 2px solid #21325d;
    color: #fff;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.3s;
  }
  
  /* .reviewDescriptionButton button:hover {} */
  
  .adultss {
    /* d-flex justify-content-center align-items-center */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 850px) {
    .mb-md-3 p {
      margin: 10px;
    }
  
    .adultss {
      /* d-flex justify-content-center align-items-center */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;
      margin-left: 12px;
    }
  }
  
  .hotelBookNowHeader-new {
    display: flex;
    padding: 5px var(--0, 0px);
    justify-content: space-evenly;
    align-items: center;
    gap: 29px;
  }
  
  .serach-hotel-discribe-new {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  
  .serach-hotel-discribe-new-content {
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-style: normal;
  
    color: #071c2c;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .serach-hotel-discribe-new-content1 {
    color: #071c2c;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .serach-hotel-discribe-new {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  
  .hotelBookNowOuter-new {
    border-radius: 16px;
    border: 1px solid #ffe7e7 !important;
    background: #fffbfb !important;
    padding: 12px;
  }
  
  .hotelDetails-new {
    display: flex;
    flex-direction: column;
    gap: 10px;
  
    border-radius: 12px;
    border: 1px solid #ffe7e7;
    background: #fffbfb;
    padding: 15px;
  }
  
  .hotelDetails-new > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: ; */
  }
  
  .hotelName-new {
    color: #000;
    /* font-family: Montserrat; */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .headText-new {
    padding: 15px;
  
    border-radius: var(--rounded, 4px);
    background: rgba(231, 60, 52, 0.15);
  }
  
  .headText-new h2 {
    color: #e73c34;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .proceed-book-new-hotel button {
    display: flex;
  
    padding: 6px;
  
    color: var(--white, #fff);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    justify-content: center;
    align-items: center;
    border-radius: var(--rounded, 4px);
    background: #e73c34;
  }
  
  .proceed-book-new-hotel button:hover {
    color: var(--white, #fff);
    background: #e73c34;
  }
  
  .bookingDetailsGuestHeader-new {
    margin-top: 15px;
    display: flex;
    flex-direction:row;
    gap: 10px;
    color: #e73c34;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: rgba(231, 60, 52, 0.15);
    padding: 15px;
    border-radius: 6px;
  }
  .hotelgrn {
    display: flex;
    gap: 10px;
  }
  .choosename {
    padding-top: 10px;
    color: #e73c34;
    font-family: Montserrat;
    font-size: 15.214px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
  .form-controls {
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d1d5db);
    background: var(--gray-50, #f9fafb);
  }
  
  .bookGuest {
    border-radius: 12px;
    border: 1px solid #ffe7e7;
    padding-bottom: 8px;
  }
  
  .leftnewdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 75%;
  }
  
  .leftnewdivsecond {
    display: flex;
  
    flex-direction: column;
    align-items: flex-end;
  }
  .guest1 {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
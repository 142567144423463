.input_area {
    width: 125px;
    height: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding: 0px 6px;
    border: none;
}
.input_option {
    width: 82px;
    height: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding: 0px 6px;
    border: none;
    font-size: 12px;
}

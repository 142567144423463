.sight_header{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 10px;
opacity: 1;
}
.input_field{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 10px;
opacity: 1;
padding: 5px;
}

.home_name::placeholder{
    padding: 0 10px;
    color: #707070;
    font-size: 10px;
    font-weight: bold;
}

.sorting_booking{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
padding: 10px;
}
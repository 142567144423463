/* Reset some default styles for all elements */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Common styles for the header */
.header {
  display: flex;
  justify-content: space-between;

  margin-top: 10px;
  width: 100%;
  height: 118px;

  border-radius: 10px;
  opacity: 1;
  background-color: white;
}

.header_scroll {

  height: 85px;
  margin: 1px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
}

/* Style for the logo */
.travvolt {
  width: 30%;
  height: 50%;
  margin: left;
  margin: auto;
}

.logout{
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
}
.travvolt>h4 {
  margin-left: 105px;
  margin-bottom: 20px;
}

/* Style for the welcome section */
/* .welcome {
  display: flex;
  gap: 20px;
 
  height:100px;
  border:"2px solid black"
} */

.welPrice {
  color: #ff8900;
}

.skd {
  font-size: larger;
  font-weight: 700;
  color: #0052d0;
}

.welcome>p {
  margin: auto;
  font-size: medium;
  font-weight: 700;
}

.cancel:hover {
  background: #ff8900;
  color: white;
}

/* Media query for screens with a minimum width of 566px */
@media screen and (min-width: 600px) {
  .header {
    /* Add styles specific to screens with a minimum width of 566px */
    font-size: 20px;
    width: 100%;

  }

  .welcome {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;

  }
}



.text {
  list-style: none;
}
.logoname {
  background-color: white;
  border-radius: 5px;
}
.active-nav {

  border-radius: 5px;
  text-align: center;
  /* background-color:#21325D; */
  border-bottom: 1px solid #E73C34;
  color:#E73C34 !important;
}
.squre:hover{
  background-color: rgb(240, 186, 153);
}

.toggle_button {
  background-color: red;
  border: none;
  width: 100%;
  height: 33px;
  border-radius: 5px;
  margin: 2px !important;
  cursor: pointer;
}

.custom-header {
  text-align: center; /* Change this to the desired color */
}

.toggle_close {
  background-color: #006fff !important;
  border: none;
  width: 100%;
  border-radius: 5px;
  margin: 2px !important;
  cursor: pointer;
}

.toggle_button {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 100%;
  border: none;
}

.tag__Container {
  margin: 40px 60px;
}

.package__Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_Textarea {
  margin-top: 10px;
  width: 700px;
  height: 100px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(215, 215, 215, 0.07);
  border-color: -moz-use-text-color #4a4a4a #504e4e -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 1) inset;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}

.style_Textarea:focus {
  background: none repeat scroll 0 0 #ffffff;
  outline-width: 0;
}


/* new */
.label__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.relevant__tag {
  width: 800px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}


.relevant__tag input {
  opacity: 1 !important;
  position: unset !important;
  height: unset !important;
  width: unset !important;
}

.label__container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  background: blanchedalmond;
  margin: 5px 9px;
  padding: 5px 25px;
  height: 50px;
  width: 340px;
  border-radius: 5px;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #ccc;
  border-radius: 25px;

  transition: 0.15s;
}

/* When the checkbox is checked, add a blue background */
.label__container input:checked~.checkmark {
  background-color: limegreen;
  border-radius: 25px;
  transition: 0.15s;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label__container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label__container .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
  margin: 2px;
}

.tag__title {
  margin-left: 7px;
  font-size: 15px;
}

.day_Textarea {
  width: 660px;
  height: 80px;
}


/* container */
.main__container {
  width: 100%;
}

.main__container_width_100 {
  width: 100%;
}

.main__container_width_75 {
  width: 85%;
  margin-top: 150px;
  margin-left: 15%;
}

/* styles.css */

.container {
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

.inputField {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
}
.list_text {
  margin-left: 12px !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666666 !important;
}

.btn_mod {
  /* background: #006fff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px !important; */
  background-color: #21325d !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}

.flight_img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 90px;
}

.hotel_name {
  text-align: left !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0px !important;
  color: #252525 !important;
  opacity: 1;
}

.filterBox {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c9caca;
  gap: 20px;
  color: #21325d;
  background-color: #fff;
}

.filterBox p {
  color: #000;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterBox label {
  color: #21325d;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filterBox select {
  padding: 4px !important;
}

.book-btn {
  background-color: #21325d;
  color: white;
  padding: 8px 12px;
}

.hotelResultBox {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
  margin-top: 24px;
}

/* .outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
} */

.ModifySearch {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
}

.outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: rgb(241, 241, 241);
}

.searchDestination b {
  text-transform: capitalize;
}

.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.filteredNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.filteredNotFound h1 {
  color: #e73c33;
  font-weight: 700;
  font-size: 22px;
}

.filteredNotFound img {
  width: 130px;
  height: 130px;
}

.hotelResultBoxSearch {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: #fffbfb;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.highSelect {
  height: 50px;
  width: 180px;
  margin-bottom: 20px;
}

.hotelResultBoxSearch > div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch p {
  margin-bottom: 0 !important;
}

.hotelImage {
  padding: 15px;
}

.hotelImage img {
  width: 250px;
  height: 180px;
}

.hotelResultDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  padding: 15px;
}

.icon-box {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.icon-box > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.priceBookHotel {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  /* border-left: 1px solid lightgray; */
}

.priceBookHotelOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  min-width: 135px;
  padding: 30px 0px;
}

.priceBookHotelOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceBookHotelOne p {
  color: #e73c33;

  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.priceBookHotelOne h3 {
  color: #fff;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 16px;
  background: #071c2c;
  display: inline-block;
}

.priceBookHotelOne h4 {
  color: #071c2c;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.priceBookHotelOne h4 svg {
  font-size: 13px !important;
}

.priceBookHotelOne b {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priceBookTwo button {
  padding: 11.28px 28.199px;
  border-radius: 5.64px;
  font-weight: 600;
  background: #21325d;
  color: #fff;
  border: 2px solid #21325d;
  transition: 0.3s all;
}

.priceBookTwo button:hover {
  background: transparent;
  color: #21325d;
}

.hotleTitle {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.customize {
  color: #e73c33;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hotAddress {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;
}

.icon-box p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hotelRating {
}

.seeMore button {
  color: #0048ff;

  font-family: Montserrat;
  font-size: 16.919px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
}

.packageResult .container-fluid {
  padding: 0px 20px !important;
}

/* style= {
        {
        width: 361,
         height: 642,
          background: 'white',
           borderRadius: 8, 
           border: '1px #5C85A4 solid', 
           padding: "10px"
    }
} */

.packResFilterBox {
  width: 100%;
  /* height: 100%; */
  /* margin: 10px 0px; */
  /* margin: 10px 0px; */
  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fff;
  padding-top: 8px;
  margin-bottom: 10px;
}

.icon-box-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 850px) {
  .packageImage {
    flex: 100% !important;
  }

  .hotelResultDetails {
    flex: 50% !important;
    width: 50%;
    margin-top: 20px;
  }

  .priceBookHotel {
    flex: 20% !important;
    margin-top: 20px;
  }

  .filterBox p {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
  }

  .filterBox {
    gap: 12px;
  }
}

.ratingStar img {
  margin-right: 8px;
}

.hotelResultBoxSearch-new {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;

  border-radius: 8px;
  border: 2px solid #ffe7e7;
  background: #fffbfb;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.hotelResultBoxSearch-new > div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch-new p {
  margin-bottom: 0 !important;
}

.showmorehotel {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  gap: 14.1px;
  color: #e73c34;
  align-self: stretch;
  border-radius: 5.64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"] {
  color: #ef233c;
  --thumb-height: 0.825em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

.mapp a {
  color: #d90429;
  text-decoration: none;
  font-weight: 600;
}

.hotelGalleryBox {
  border-radius: 10px;
  height: 400px;
}

.hotelGalleryBox img {
  border-radius: 10px;
  height: 400px !important;
  object-fit: unset !important;
}

.breakCancel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* width: ; */
}

.breakCancel .brcl1 {
  font-size: 12px;
  font-weight: 600;
  color: rgb(19, 94, 19);
}

.breakCancel .brcl2 {
  font-size: 12px;
  font-weight: 600;
  color: rgb(155, 14, 14);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 400px !important;
}

.hotelGalleryBox .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 54px !important;
  width: 60px;
}

.bookDetail p {
  font-weight: 600;
  margin-bottom: 10px;
}

.selCount input::placeholder {
  color: #3f454a;
}

.GrnButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.GrnButton button {
  padding: 18px 50px;
  border-radius: 10px;
  border: none;
  background-color: #e73c34;
  color: white;
}

.propertyRulesBox {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.propertyRulesBox .prOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: #3f454a;
}

.propertyRulesBox .prTwo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.propertyRulesBox .prTwo p {
  font-weight: 600;
  color: #3f454a;
  margin-bottom: 10px;
}

.othInc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
}

.othInc span {
  padding: 2px 8px;
  border: 1px solid green;
  border-radius: 3px;
  color: rgb(3, 87, 3);
  background-color: rgba(171, 247, 171, 0.521);
}

.hotelGall img {
}

.antImgGall .ant-image-img {
  border-radius: 8px !important;
  height: 330px !important;
}

.antImgGallSmall .ant-image-img {
  border-radius: 8px !important;
  height: 157px !important;
}

.bookRoomHotDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.hotelBookDesignFirst {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.othInc {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.othIncInner {
  padding: 4px 0px;
}

/* filter css starts here  */

.hotelFilterNew {
  background-color: #fff !important;
  padding: 10px !important;
}

.hotelFilterNew input[type="checkbox"]:checked {
  background-color: #e73c34;
}

.hotelFilterNew input[type="checkbox"] {
  background-color: #e73c34;
}

.hotelFilterNew p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.hotelSearchFilter {
  display: flex;
  flex-direction: column;
}

.hotelSearchFilter input {
  border: none;
  padding: 9px 4px;
  font-size: 13px;
  border-radius: 5px;
}

.hotelSearchFilter input:focus {
  border: none !important;
  outline: none !important;
}

.hotelFilterOuter {
  padding: 14px 8px;
  background: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
}

.hoterRatingFilter {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.hoterRatingFilter label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}

.hoterFacilityFilter {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.hoterFacilityFilter label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}

.hotelPriceSlider:where(.css-dev-only-do-not-override-j9bb5n).ant-slider {
  box-sizing: border-box;
  margin: 0px 0px !important;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  height: 10px;
  cursor: pointer;
  touch-action: none;
}

.hotelPriceSlider:where(.css-j9bb5n).ant-slider {
  box-sizing: border-box;
  margin: 0px 0px !important;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  height: 10px;
  cursor: pointer;
  touch-action: none;
}

.hotelPriceSlider:where(.css-j9bb5n).ant-slider {
  box-sizing: border-box;
  margin: 0px 0px !important;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  height: 10px;
  cursor: pointer;
  touch-action: none;
}

.hotelPriceSlider:where(.css-dev-only-do-not-override-j9bb5n).ant-slider
  .ant-slider-track {
  background-color: #000 !important;
  border-radius: 2px;
}

.hotelPriceSlider:where(.css-j9bb5n).ant-slider .ant-slider-track {
  background-color: #000 !important;
  border-radius: 2px;
}

:where(.css-j9bb5n).ant-slider .ant-slider-track {
  background-color: #000 !important;
  border-radius: 2px;
}

.ant-slider-track {
  background-color: #000 !important;
  border-radius: 2px;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-slider
  .ant-slider-handle:hover::after,
:where(.css-dev-only-do-not-override-j9bb5n).ant-slider
  .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-j9bb5n).ant-slider
  .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #e73c34 !important;
  width: 12px;
  height: 12px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

:where(.css-j9bb5n).ant-slider .ant-slider-handle:hover::after,
:where(.css-j9bb5n).ant-slider .ant-slider-handle:active::after,
:where(.css-j9bb5n).ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #e73c34 !important;
  width: 12px;
  height: 12px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

.ant-slider-handle:hover::after,
.ant-slider-handle:active::after,
.ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #e73c34 !important;
  width: 12px;
  height: 12px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

.ant-slider .ant-slider-handle::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px #e73c34;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
    height 0.2s, box-shadow 0.2s;
}

/* filter css ends here  */

.dangerousHTMLhotel {
  font-size: 14px;
}

.dangerousHTMLhotel > p {
  margin-bottom: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.9 !important;
}

.dangerousHTMLhotel b {
  display: block !important;
  font-weight: 600 !important;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #e73c34 !important;
  text-shadow: 0 0 0.25px currentcolor;
  font-weight: 600 !important;
}

:where(.css-j9bb5n).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #e73c34 !important;
  font-weight: 600 !important;
  text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs
  .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs
  .ant-tabs-tab-remove:active {
  color: #e73c34 !important;
}

:where(.css-j9bb5n).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-j9bb5n).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-j9bb5n).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-j9bb5n).ant-tabs .ant-tabs-tab-remove:active {
  color: #e73c34 !important;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs .ant-tabs-tab:hover {
  color: #e73c34 !important;
}

:where(.css-j9bb5n).ant-tabs .ant-tabs-tab:hover {
  color: #e73c34 !important;
}

:where(.css-j9bb5n).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #e73c34 !important;
  pointer-events: none;
}

:where(.css-j9bb5n).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #e73c34 !important;
  pointer-events: none;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #e73c34 !important;
  pointer-events: none;
}

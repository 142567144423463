.tableadagent {
    background-color: #fff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Your desired box shadow */
    border-radius: 8px; /* Your desired border radius */
    
  }
  .custom-table-container{
    background-color: #fff !important;
  }
  .tableadagents{
    background-color: #fff !important;
  }
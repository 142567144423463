.hotelLoading {
    position: fixed;
    /* position: relative; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999 !important;
    /* margin-top: 65px; */
}

.hotelLoading img {
    width: 300px;
    height: 238px;
}
.button {
  position: relative;
  overflow: hidden;
  height: 2rem;
  width: auto;
  /* padding: 0 2rem; */
  border-radius: 0.5rem;

  background-size: 400%;
  color: #fff;
  border: none;
  padding: 8px;
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
  /* border: 3PX solid red; */
  text-align: center;
  padding: 0%;
  margin: 10px auto;
}
.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  /* border-radius: inherit; */
  background: linear-gradient(82.3deg, #e84941, #e94f47);
  transition: all 0.475s;
}

/* .button:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgba(150, 93, 233, 1) 10.8%,
    rgba(99, 88, 238, 1) 94.3%
  );
  transition: all 0.475s;
} */

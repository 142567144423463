/* AdminLogin.css */

:root {
  --c-blue: #21325d;
  --c-white: #fefefe;
  --t-blue: #21325d;
  --g-blue: linear-gradient(var(--t-blue), var(--t-blue));
  --s-basic: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* body {
  padding-bottom: 20px;
  font-family: "Nunito", sans-serif;
  color: var(--c-white);
  background: var(--c-white);
} */

.Adminwrapper {
  margin: auto;
  width: 350px;
  box-shadow: var(--s-basic);
  margin-top: -80px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  /* height:57vh; */
}

.sectionad {
  padding: 1rem;
  background-color: #21325d;
  width: 300px;
  box-shadow: 0px 4px 8px rgba(33, 50, 93, 0.5);
  /* Add shadow to the color #21325d */
  top: -20px;
  /* Set a negative margin from the top */
  margin: auto;
  border-radius: 5px;
  color: var(--c-white);
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
  z-index: 1;
}

/* AdminLogin.css */





.headead h1 {
  font-size: 2rem;
  color: #fff;
  /* Adjust the color as needed */
}

.headers {
  position: relative;
  text-align: center;

  &__text {
    position: relative;
    padding: 3.5rem 0 7rem;

    >h1 {
      margin: 0;
      font-size: 2.5rem;
    }
  }

  >.trapezoid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skewY(-10deg);
    transform-origin: top left;
    box-shadow: var(--s-basic);
    background: var(--g-blue),
      url("https://images.pexels.com/photos/520936/pexels-photo-520936.jpeg?w=940&h=650&auto=compress&cs=tinysrgb");
    background-position: top center;
    background-attachment: fixed;
  }
}

/* AdminLogin.css */

/* ... (existing styles) ... */

.formadmin {
  margin: 0 auto;
  max-width: 20rem;
  margin-top: -25px;
  overflow: auto;

  >*+* {
    margin-top: 1rem;
  }

  >.admininput {
    border: 1px solid var(--c-blue);
    border-radius: 5px;
    width: 100%;

    padding: 0.5rem;
    font-size: 1rem;
    color: var(--c-blue);
    background: var(--c-white);

    outline: none;

    &:focus {
      border-color: var(--t-blue);
    }

    &::placeholder {
      color: var(--c-blue);
    }
  }

  >.btnadmin {

    border: 0;
    border-radius: 5px;
    width: 100%;
    padding: 0.85rem;
    font-size: 1rem;
    color: var(--c-white);
    background: var(--c-blue);
    margin-top: -2px;

    &:focus {
      outline: none;
    }
  }

  >p {
    margin: 0.25rem 0 1rem;
    text-align: center;
    color: var(--c-blue);
  }

  /* Add styles for the eye icon */
  .password-container {
    position: relative;
    margin-top: -5px;
  }

  .password-container .admininput {
    border: 1px solid var(--c-blue);
    border-radius: 5px;
    width: calc(100%);

    padding: 1rem;
    font-size: 1rem;
    color: var(--c-blue);
    background: var(--c-white);

    outline: none;

    &:focus {
      border-color: var(--t-blue);
    }

    &::placeholder {
      color: var(--c-blue);
    }
  }

  .password-container .eye-icon {
    position: absolute;
    top: 38%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--c-blue);
  }
}

/* ... (existing styles) ... */
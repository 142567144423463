.password {
  width: 10px !important;
  text-align: left;
}

.modal__img {
  width: "300px";
  height: "200px";
  transition: 2s all ease-in;
  cursor: pointer;
}

.mark__up__btn {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 34px;
  padding: 6px 4px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #21325D !important;
  color: #fff !important;
  margin: 0 0 3px -10px !important;
}

.add_vendor_btn {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 34px;
  padding: 6px 4px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #21325D !important;
  color: #fff !important;

}


.adminseacrch {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  width: 93%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* Centering the search bar horizontally */
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Add box shadow here */
}


.tablead {
  background-color: #fff;
  border: none;
  color: black;

}

.subuser-table-container {
  margin-top: -20px;
}


.tableheadadmin {
  background-color: none !important;
}

.addVendorButton {
  /* Add your "Add Amount" button styles here */
  background-color: #007bff;
  /* Change the background color as needed */
  color: #ffffff;

  /* Change the text color as needed */
  &:hover {
    background-color: #0056b3;
    /* Change the hover background color as needed */
  }
}

/* Agenttable.css */
.dataGrid .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  opacity: 1;
  visibility: visible;
}

/* .flightLoading {
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9999 !important;
    background-color: white !important;
    position: absolute;
} */


/* LoadingSpinner.css */
.flightLoading {
    position: fixed;
    /* position: relative; */
    overflow-y: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #cceaf7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999 !important;
    /* Ensure the spinner is above other content */
}

.flightLoading img {
    /* width: 500px; */
    /* margin-top: 100px; */
    /* Adjust the size of the loading GIF */
    /* height: 500px; */
    /* Adjust the size of the loading GIF */
}

.flighloadingtext {
    text-align: center;
}

.busimgcontainer {
    width: 300px;
    height: 300px;
    border-radius: 2% !important;

}

.busimgcontainer>img:first-child {
    width: 100%;
    height: 100%;
    border-radius: 2%;
}

.imageflightcontent {
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 94px;
}

.funnyfact>div>p {
    font: bold;
    font-size: 12px;
}
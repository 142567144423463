.list_text {
  margin-left: 12px !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666666 !important;
}

.btn_mod {
  /* background: #006fff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px !important; */
  background-color: #21325d !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}

.flight_img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 90px;
}

.hotel_name {
  text-align: left !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0px !important;
  color: #252525 !important;
  opacity: 1;
}

.filterBox {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c9caca;
  gap: 20px;
  color: #21325d;
  background-color: #fff;
}

.filterBox p {
  color: #000;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterBox label {
  color: #21325d;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filterBox select {
  padding: 4px !important;
}

.book-btn {
  background-color: #21325d;
  color: white;
  padding: 8px 12px;
}

.hotelResultBox {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
  margin-top: 24px;
}

/* .outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
} */

.ModifySearch {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
}

.outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: rgb(241, 241, 241);
}

.searchDestination b {
  text-transform: capitalize;
}

.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.filteredNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.filteredNotFound h1 {
  color: #e73c33;
  font-weight: 700;
  font-size: 22px;
}

.filteredNotFound img {
  width: 130px;
  height: 130px;
}

.hotelResultBoxSearch {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;

  border: 2px solid #ffe7e7;

  background: #fffbfb;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.highSelect {
  height: 50px;
  width: 180px;
  margin-bottom: 20px;
}

.hotelResultBoxSearch > div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch p {
  margin-bottom: 0 !important;
}

.hotelImage {
  padding: 15px;
}

.hotelImages {
  padding-top: 15px;
}

.hotelImage img {
  width: 250px;
  height: 180px;
}

.hotelResultDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  padding: 15px;
}

.icon-box {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.icon-box > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.priceBookHotel {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  /* border-left: 1px solid lightgray; */
}

.priceBookHotelOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  min-width: 135px;
  padding: 30px 0px;
}

.priceBookHotelOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceBookHotelOne p {
  color: #e73c33;

  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.priceBookHotelOne h3 {
  color: #fff;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 16px;
  background: #071c2c;
  display: inline-block;
}

.priceBookHotelOne h4 {
  color: #071c2c;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.priceBookHotelOne h4 svg {
  font-size: 13px !important;
}

.priceBookHotelOne b {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priceBookTwo button {
  padding: 11.28px 28.199px;
  border-radius: 5.64px;
  font-weight: 600;
  background: #21325d;
  color: #fff;
  border: 2px solid #21325d;
  transition: 0.3s all;
}

.priceBookTwo button:hover {
  background: transparent;
  color: #21325d;
}

.hotleTitle {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.customize {
  color: #e73c33;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hotAddress {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;
}

.icon-box p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hotelRating {
}

.seeMore button {
  color: #0048ff;

  font-family: Montserrat;
  font-size: 16.919px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
}

.packageResult .container-fluid {
  padding: 0px 20px !important;
}

/* style= {
        {
        width: 361,
         height: 642,
          background: 'white',
           borderRadius: 8, 
           border: '1px #5C85A4 solid', 
           padding: "10px"
    }
} */

.packResFilterBox {
  width: 100%;
  /* height: 100%; */
  /* margin: 10px 0px; */
  /* margin: 10px 0px; */
  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fff;
  padding-top: 8px;
  margin-bottom: 10px;
}

.icon-box-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 850px) {
  .packageImage {
    flex: 100% !important;
  }

  .hotelResultDetails {
    flex: 50% !important;
    width: 50%;
    margin-top: 20px;
  }

  .priceBookHotel {
    flex: 20% !important;
    margin-top: 20px;
  }

  .filterBox p {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
  }

  .filterBox {
    gap: 12px;
  }
}

.ratingStar img {
  margin-right: 8px;
}

.hotelResultBoxSearch-new {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;

  border-radius: 8px;
  border: 2px solid #ffe7e7;
  background: #fffbfb;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.hotelResultBoxSearch-new > div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch-new p {
  margin-bottom: 0 !important;
}

.showmorehotel {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  gap: 14.1px;
  color: #e73c34;
  align-self: stretch;
  border-radius: 5.64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
}
.showmore {
  border-radius: 5.64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
  display: flex;
  padding: 8.28px 25px;
  justify-content: center;
  align-items: center;
  gap: 14.1px;
  align-self: stretch;
  color: #e73c34;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sortby {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 127%; /* 17.78px */
}

.hotelContainer {
  padding: 0px 20px !important;
  width: 100% !important;
  height: 100%;
  border-radius: 10px;
  background: white;
  border: "2px solid red";
}

.nhotel_form_input {
  height: 100% !important;
  position: relative;
  width: 90% !important;
}

.nhotel_form_input input {
  width: 100%;
  height: 100%;
  padding-left: 25px;
  border-radius: 10px;
  border: 3px solid #70707069;
}

.hotel_form_input {
  height: 50px !important;
  position: relative;
  width: 100% !important;
  /* z-index: 99999999; */
}

#CitySearchID + ul {
  max-height: 150px;
  list-style: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 6px;
  padding: 5px;
  overflow-y: scroll;
  z-index: 500;
  position: absolute;
  width: 100%;
  background-color: white;
}

#CitySearchID + ul > li {
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: white;
}

.hotel_form_input input {
  height: 100%;
  padding-left: 25px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--gray-50, #f9fafb);
}
.hotel_input_select {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--gray-50, #f9fafb);
}
#datepic {
  height: 50px;
  padding-left: 25px;
  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fffbfb;
}

.hotel_form_input .lable {
  font-size: 13px;
  color: #4e4c4c;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
}

.hotel_form_input .from_input::placeholder,
.to_input::placeholder {
  color: #4e4c4ca1;
  font-size: 18px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
}

.form_lable {
  color: #4e4c4c;
  font-size: 13px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  left: 10px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  top: 24px;
  transform: translateY(-30px) translateX(10px);
  background: #fff;
  padding: 0px 8px;
}

.form_lable1 {
  color: #4e4c4c;
  font-size: 13px;
  font-weight: Bold;
  margin-bottom: 1rem;
}

.hotel_input_select {
  width: 100%;
  /* border: 10px; */
  border-radius: 10px;
  height: 3.1rem;

  margin: 0 !important;
  font-weight: 600;
}

.nhotel_input_select {
  width: 100%;
  border: 10px;
  border-radius: 10px;
  height: 3.1rem;

  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fffbfb;
}

.hotel_submit {
  color: white;
  background-color: #00bdc4;
  width: 154px;
  height: 36px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  opacity: 1;
}

.hotel_submit:hover {
  background-color: rgb(61, 144, 144);
  color: white;
}

.error {
  color: red;
}

@media (max-width: 1240px) {
  .flightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flightContainer > Box {
    width: 90%;
  }
}

.hotelContainer {
  /* background-color: rgb(248, 243, 243); */
  background-color: #fff;
  border: 2px solid #e4e0e0;
  margin: auto;
  width: 100%;
  border-radius: 12px;
  margin-top: -30px !important;
}

.formFlightSearch {
  /* margin-top: 20px; */
  display: flex;
  flex-wrap: wrap;
}

.hotel_form_input {
  height: 90% !important;
  /* position: relative !important; */
  width: 100% !important;
}

.hotel_form_input input {
  width: 100% !important;
  height: 50px !important;
  padding-left: 9px !important;
  border-radius: 10px !important;
  border: 1px solid #999 !important;
}

.hotel_form_input input::placeholder {
  font-weight: 600;
}

.form_label {
  font-size: 15px !important;
  font-weight: 700 !important;
  font-family: "Quicksand", sans-serif !important;
  left: 10px !important;
  line-height: 14px !important;
  pointer-events: none !important;
  position: unset !important;
  transform-origin: 0 50%;
  top: 24px;
  transform: translateY(-6px) translateX(-1px) !important;
  background: unset !important;
  padding: 0px 8px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

/* .room-container {
  background-color: red;
} */

.delete-button {
  padding: 8px 15px;
  border: 2px solid rgb(33, 50, 93);
  color: rgb(33, 50, 93);
  background-color: white;
  border-radius: 5px;
}

.searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
  height: 50px;
  border: 3px solid #21325d;
  border-radius: 8px;
  color: white;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 600;
  word-wrap: break-word;
  background: #21325d;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: auto;
  margin-top: 30px;
  transition: all 0.3s;
}

.searchButton:hover {
  background-color: white;
}

#cssbuttons-io-button {
  margin: 0 !important;
}

.button-row {
  padding: 0 !important;
  display: flex;
  justify-content: center;
}

.hotelFormbutton {
  padding: 10px 22px;
  padding: 8px 30px;
  border-radius: 5.302px;
  background: #21325d;
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: 2px solid #21325d;
}

.hotelFormbutton-new button {
  display: flex;
  width: 40%;
  border-radius: var(--rounded-lg, 8px);
  background: #e73c34;

  padding: var(--25, 10px) var(--5, 20px);
  justify-content: center;
  align-items: center;
  color: white !important;
  gap: var(--2, 8px);
}

.hotelFormbutton-new button:hover {
  display: flex;
  width: 44%;
  border: 1px solid #e73c34 !important;
  color: white !important;
  gap: var(--2, 8px);
}

.sidebar-label-container-new {
  display: flex !important;
  position: relative !important;
  /* padding-left: 35px !important; */
  margin-bottom: 12px !important;
  justify-content: flex-end;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 400 !important;
}

.sidebar-label-container-new-outer-div label {
  font-size: normal !important;
}

.sidebar-label-container-new input {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

.checkmark .sidebar-label-container-new:hover input ~ .checkmark {
  background-color: #ccc;
}

.sidebar-label-container-new input:checked ~ .checkmark {
  background-color: #e73c33 !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-label-container-new input:checked ~ .checkmark:after {
  display: block;
}

.sidebar-label-container-new .checkmark:after {
  top: 4.4px;
  left: 4.4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.sidebar-label-container-new:has(input:checked) .svgBOx {
  border: 1px solid #d90429 !important;
}

.sidebar-label-container-new:has(input:checked) > div .checkedSVG svg {
  fill: #d90429;
}

.sidebar-label-container-new:has(input:checked) > div span {
  color: #d90429;
}

/* .busDepartureMain .sidebar-label-container-new {
  margin-bottom: 0 !important;
} */
.container-fluids {
  padding: 32px 0px;
}

#margin-pecentage-large-hotel {
  margin: 0px 10%;
  background-color: white;
  padding: 10px 15px;
  border-radius: 8px;
}

.filtersection {
  border-radius: 16px;
  border: 1px solid var(--gray-300, #d1d5db);
}

.clearall {
  color: #e73c34;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
  text-decoration-line: underline;
}

.filterssnew {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
.filterTitles {
  display: flex;
  width: 240px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
}
.filterimgnew {
  display: flex;
}
.newcheckbox {
  width: 16px;
  height: 16px;
  border-radius: 1px;
  border: 0.952px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
}
.itemnew {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.newfilterbox {
  display: flex;
  align-items: center;
  gap: 8px;
}
.firstdivstar {
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.sidebar-titles {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
}

.hide-scrollbar {
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.filterBox {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  /* border: 1px solid #c9caca; */
  gap: 10px;
  color: #21325d;
  background-color: #fff;
}

.filterBox p {
  color: #000;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterBox label {
  color: #21325d;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filterBox select {
  padding: 4px !important;
}

.book-btn {
  background-color: #21325d;
  color: white;
  padding: 8px 12px;
}

.ModifySearch {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background-color: #fff;
  /* box-shadow: 0px 0px 24px -11px; */
}

.outerFilterBox {
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.searchDestination b {
  text-transform: capitalize;
}

.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.filteredNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.filteredNotFound h1 {
  color: #d90429;
  font-weight: 700;
  font-size: 22px;
}

.filteredNotFound img {
  width: 130px;
  height: 130px;
}

.exceptionalFlex {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.hotelResultBoxSearch {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: #fffbfb;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.highSelect {
  height: 50px;
  width: 180px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: white;
  text-align: center;
  border: 1px solid lightgray;
}

.hotelResultBoxSearch > div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch p {
  margin-bottom: 0 !important;
}

.hotelImage {
  padding: 15px;
}

.hotelImage img {
  width: 250px;
  height: 180px;
}

.hotelResultDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  padding: 15px;
}

.icon-box {
  display: flex;
  gap: 10px;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
}

.icon-box > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.priceBookHotel {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  border-left: 1px solid lightgray;
}

.priceBookHotelOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  min-width: 135px;
  padding: 30px 0px;
}

.priceBookHotelOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceBookHotelOne p {
  color: #d90429;

  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.priceBookHotelOne h3 {
  color: #fff;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 16px;
  background: #071c2c;
  display: inline-block;
}

.priceBookHotelOne h4 {
  color: #071c2c;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.priceBookHotelOne h4 svg {
  font-size: 13px !important;
}

.priceBookHotelOne b {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priceBookTwo button {
  padding: 11.28px 28.199px;
  border-radius: 5.64px;
  font-weight: 600;
  background: #21325d;
  color: #fff;
  border: 2px solid #21325d;
  transition: 0.3s all;
}

.priceBookTwo button:hover {
  background: transparent;
  color: #21325d;
}

.hotleTitle {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.customize {
  color: #d90429;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hotAddress {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;
  max-width: 218px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotAddressLocation {
  color: #d90429;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-box p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hotelRating {
}

.seeMore button {
  color: #0048ff;

  font-family: Montserrat;
  font-size: 16.919px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
}

.packageResult .container-fluid {
  padding: 0px 20px !important;
}

/* style= {
        {
        width: 361,
         height: 642,
          background: 'white',
           borderRadius: 8, 
           border: '1px #5C85A4 solid', 
           padding: "10px"
    }
} */

.packResFilterBox {
  width: 100%;
  /* height: 100%; */
  /* margin: 10px 0px; */
  /* margin: 10px 0px; */
  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fff;
  padding-top: 8px;
  margin-bottom: 10px;
}

.icon-box-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 850px) {
  .packageImage {
    flex: 100% !important;
  }

  .hotelResultDetails {
    flex: 50% !important;
    width: 50%;
    margin-top: 20px;
  }

  .priceBookHotel {
    flex: 20% !important;
    margin-top: 20px;
  }

  .filterBox p {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
  }

  .filterBox {
    gap: 12px;
  }
}

.ratingStar img {
  margin-right: 8px;
}

.mainimgHotelSearch {
  position: relative;
}

.searchMainBoxAbs {
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: -1;
}

.HotelResultSearchBarBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: white; */
  border-radius: 10px;
  /* padding: 15px; */
  /* backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: #6b696973;
  border: 1px solid #ffffff2e;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.hotelResSurBox h3 {
  color: white;
  font-weight: 700;
  font-size: 28px;
}

.hotelResSurBox p {
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.searchBarHotelFOrm input {
  padding: 14px;
  border-radius: 8px;
  border: none;
  min-width: 290px;
}

.mainimgHotelSearchResult {
  /* background-image: url(http://localhost:3000/static/media/hotelNavBg.46afce9….jpg); */
  background-size: cover;
  background-color: #f9eded;
  background-repeat: no-repeat !important;
  height: 200px;
  position: relative;
  z-index: 3;
  background-image: linear-gradient(
    to right,
    #84c2c8 0%,
    #ade6e8 51%,
    #84c2c8 100%
  );
}

.ShowMoreHotel {
  color: #d90429;
}

.rotttt {
  transform: rotate(180deg);
}

.hotelResSurBox {
  text-align: left;
}

input[type="range"] {
  font-size: 1.5rem;
  width: 100%;
}

input[type="range"] {
  color: #ef233c;
  --thumb-height: 0.825em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
  color: #2b2d42;

  --thumb-height: 1.375em;
  --thumb-width: 0.5em;
  --clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
  html {
    /* background-color: #000; */
  }

  html::before {
    background: radial-gradient(circle at center, #101112, #000);
  }

  input[type="range"] {
    color: #f07167;
    --track-color: rgba(255, 255, 255, 0.1);
  }

  input[type="range"].win10-thumb {
    color: #3a86ff;
  }
}

/* === range commons === */
input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

.PackageDepartureMain {
  /* display: flex; */
  padding: 14px 8px;
  background: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
}

.ClearFilterOneyOneContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ClearFilterOneyOneItemDev {
  display: flex;
  justify-content: space-between;
  background-color: #f7dcdc;

  border-radius: 15px;
  align-items: center;
  padding: 4px 4px;
  margin: 2px 4px;
}
.ClearFilterOneyOneItem {
  font-size: 13px;
  color: #6c757d;
  text-transform: capitalize;
}
.ClearFilterOneyOneItemX {
  font-size: 9px;
  color: #d90429;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 13px;
  border-radius: 50%;
  font-weight: bold;
  /* color: #fff; */
  cursor: pointer;
  border: #d90429;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ClearFilterOneyOneItemX:hover {
  background-color: #d90429;
  color: #fff;
}

.breakCancel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* width: ; */
}

.breakCancel .brcl1 {
  font-size: 12px;
  font-weight: 600;
  color: rgb(19, 94, 19);
}

.breakCancel .brcl2 {
  font-size: 12px;
  font-weight: 600;
  color: rgb(155, 14, 14);
}
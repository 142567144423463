.tableModal .modal-backdrop.show {
  opacity: 0.1 !important;
}

.modal-backdrop {
  --bs-backdrop-bg: none !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  color:white !important;
}

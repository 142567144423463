/* AddSubadmin.css */

.form-container {
 
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agent-image {
  margin-right: 10px;
}

.form-title {
  font-size: 1.5rem;
  color: #333;
}

/* .form-group {
  margin-bottom: 15px;
} */

.form-input-authType{
  width: 70%;
  padding: 5px;
  border: 1px solid #ddd;
  gap:0px;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.form-label-subAdmin {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}



.form-button-sub{
  background-color: #21325D;
  color: white;
  padding: 10px 130px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
 
}
.agent-container {
  padding-top: 80px;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  overflow-x: scroll;
  position: relative;
}
#adssearch{
  box-sizing: content-box!important;
}

/* Entire table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f0f0f0;
}

/* Pagination buttons */
/* button {
      margin: 5px;
      padding: 1px 10px;
      border: none;
      border-radius: 4px;
      background-color:black;
      color: #fff;
      cursor: pointer;
    } */

.agentButton {
  margin: 5px;
  border: none;
  padding: 1px 10px;
  border-radius: 4px;
  background-color: rgb(20, 20, 20);
  color: #ffffff;
  cursor: pointer;
  justify-content: right;
}

button:hover {
  background-color: rgb(72, 72, 72);
  color: white
}

/* Active page button */
button.active {
  background-color: #f3f4f4;
}


select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: #000;
  cursor: pointer;
  margin: 0px;
  /* height: 100%; */
}

/* Green background for APPROVED status */
.approved {
  background-color: lightgreen;
}

/* Red background for REJECT status */
.rejected {
  background-color: #ff9999;
  /* Light red */
}

/* Blue background for PENDING status */


.subada-table-container {

  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 90px;
  position: relative;
  /* Added for positioning */
}

/* styles.css */
.custom-table-container {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Your desired box shadow */
  border-radius: 8px; /* Your desired border radius */
}





.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 20px;
  color: #888;
}

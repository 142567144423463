/* UserTable.css */

.user-table-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 90px;
    position: relative; /* Added for positioning */
}

.heading-container {
    text-align: center;
    margin-bottom: 20px;
}

.adtable-heading {
    color: #fff;
}

.table-name {
    color: #555;
}

.adsearch-bar {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #2196f3;
    color: white;
    width:93%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Centering the search bar horizontally */
    padding:20px;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-top: -50px;
    
}
.profile-image{
    width:100px;
    height: 100px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-button {
    margin: 0 5px;
}

/* Override Bootstrap styles for pagination button color */
.page-button.btn-primary {
    background-color: #2196f3;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.page-button.btn-primary:hover {
    background-color: #1565c0;
}
.tablead{
    background-color: #fff !important;
    color:#fff;
    border: none;
}
.tablecontainerab{
    
    border: none; 
}
.holiday_submit {
  color: white;

  width: 200px;
  height: 56px;
  text-align: center;
  opacity: 1;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #5c85a4;

  background: #fffbfb;
}

.search__Input {
  margin: 5px 15px !important;
  /* height: 50px !important; */
  /* padding-left: 8px !important; */
  /* padding-right: 45px !important; */
}

.form-control {
  border-radius: 8px !important;
  border: 1px solid #5c85a4 !important;
  background: #fffbfb !important;
}

.form-floating label {
  color: #bbb;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.holiday_submit:hover {
  background-color: rgb(61, 144, 144);
  color: white;
}

.error {
  color: red;
}

.packageFirstPage {
  max-width: 900px;
  margin: 0px 30px;
  padding: 40px;
}

.buttonBoxHoliday {
  display: flex;
  justify-content: center;
  align-items: center;
}

.holidayFirstPage {
  height: 270px;
  width: 100%;
  border-radius: 12px 0px 0px 12px;
  background-image: url(../../../Images/TOur\ Package.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holidayForm .label {
  margin-bottom: 8px;
  color: #000;

  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.holidayForm .input::placeholder {
  /* color: #bbb;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; */
}

.holidayForm .input {
  /* height: 50px;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #21325d;
    border-radius: 8px;
    width: 100%;
  
    color: #3e3d3d;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; */
}

.buttonBoxHoliday button {
  width: 100%;
  height: 50px;
  background-color: #21325d;
  color: white;
  border: 2px solid #21325d;
  transition: 0.3s all;
  border-radius: 7px;
}
.holidayFormPosition {
  position: relative;
}
.holidayPackagefromchild {
  /* position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 99999; */
  border-radius: 4px;
  background: hsla(0, 0%, 100%);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);

  position: absolute;
  left: 0;
  top: 58px;
  max-height: 200px;
  overflow: hidden;
  z-index: 999999;
  /* background: #ffffff;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); */
  /* padding: 11px 10px 11px 30px; */
  outline: 0;
  border: 0;
  width: 100%;
  font-size: calc(var(--font-scale, 1) * 16px);
  color: #000000;
  font-weight: 700;
}
.holidayPackagefromchildSearchDev {
  /* color: #9b9b9b; */
  /* font-size: 12px;
      font-weight: 900; */
  line-height: 12px;
  padding: 15px 15px;
  /* text-transform: uppercase; */
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  font-weight: 700;
  background-color: #ffffff;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.dest-citypicker-title {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 900;
  line-height: 12px;
  padding: 15px 0px 0px 15px;
  text-transform: uppercase;
  background: hsla(0, 0%, 100%, 0.726);
}
.dest-city-container {
  width: 100%;
}
.dest-city-container-div {
  padding: 10px 15px;
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.726);
  text-transform: capitalize;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 5px;
  align-items: center;
}
.dest-city-container-div:hover {
  background: hsl(0deg 7.99% 88.9% / 72.6%);
  border-radius: 0;
  border-right: 1px solid #e7e7e7;

  flex-grow: 1;
  margin-right: 0;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
}
.packageScroll {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
  width: 100%;
}
.package-p {
  font-size: 15px;
  font-weight: 400;
}

.buttonBoxHoliday-new button {
  display: flex;
  border-radius: var(--rounded-lg, 8px);
  background: #e73c34;
  padding: var(--25, 10px) var(--5, 20px);
  justify-content: center;
  align-items: center;
  color: #ffffff;
  gap: var(--2, 8px);
  flex-shrink: 0;
}

.buttonBoxHoliday-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonBoxHoliday-new button:hover {
  border: 1px solid #e73c34 !important;
  padding: var(--25, 10px) var(--5, 20px);
  color: #000;
}

.body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10 !important;
}

.loader {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  z-index: 10 !important;
  /* transform: translate(-50%, -50%); */
}

.loader::before {
  content: "";
  background-image: url(../../../../Images/hotel-loader.png);
  opacity: 0.5;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* .wait {
  margin: 5rem 0;
}

.iata_code {
  font-size: 6rem;
  opacity: 0.3;
  top: 52%;
  position: absolute;
  color: #0099cc;
}

.departure_city {
  left: 2 rem;
}

.arrival_city {
  right: 0rem;
}

.plane {
  position: absolute;
  margin: 0 auto;
  width: 100%;
}

.plane-img {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.earth-wrapper {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  padding-top: 2.7rem;
}

.earth {
  width: 160px;
  height: 160px;
  background: url("https://zupimages.net/up/19/34/6vlb.gif");
  border-radius: 100%;
  background-size: 340px;
  animation: earthAnim 12s infinite linear;
  margin: 0 auto;
  border: 1px solid #cdd1d3;
}

@keyframes earthAnim {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -340px;
  }
}

@media screen and (max-width: 420px) {
  .departure_city {
    left: 0;
    right: 0;
    top: 30%;
    position: absolute;
    margin: 0 auto;
  }

  .arrival_city {
    left: 0;
    right: 0;
    top: 93%;
    position: absolute;
    margin: 0 auto;
  }
} */

.logo-bx {
  max-width: 300px;
}

.logo-bx img {
  width: 100%;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
}

.bottom-box {
  background-color: #fff;
  border-radius: 15px;
  width: 550px !important;
  margin: 0px 20px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.loader-content h2 {
  font-size: 20px;
  font-weight: 600;
  color: #071c2c;
}

.loader-content p {
  font-size: 15px;
  margin-bottom: 5px;
}

.loader-content h3 {
  font-size: 18px;
  color: #071c2c;
}

.loader-details {
  background: #d2f8e7;
  display: flex;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.loader-details > div {
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.loader-details p {
  font-size: 13px;
  margin: 0 !important;
}

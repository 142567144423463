.transfer_guest{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.dtail_guest{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    opacity: 1;
}

.car_txt{
    text-align: left !important;
    font-size: 14px !important;
    font-weight: bold !important;
    letter-spacing: 0px;
    color: #252525 !important;
    opacity: 1;
}
.car_link_txt{
    text-align: left;
    font-size: 14px;
    font-weight: bold !important;
    letter-spacing: 0px;
    color: #FF8900;
    opacity: 1;
}
.link_text{
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #006FFF;
    opacity: 1;
}
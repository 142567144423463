.Top_header {
  justify-content: space-between;
  display: flex;
}

.Top_txt {
  text-align: left;
  letter-spacing: 0px;
  font-size: 16px !important;
  font-size: bold;
  color: #008fcc;
  opacity: 1;
}

.sub_txt {
  text-align: left;
  font-size: 10px !important;
  font-size: bold;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.mid_header {
  border-radius: 4.587px;

  background: #fffbfb;
}

.form_input_select {
  width: 150px;
  border: 10px;
  border-radius: 10px;
  height: 2.5rem;
  border: 3px solid #70707069;
}

.inner_box {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 10px 15px;
}

.lis_item {
  color: "red";
  font-size: 14px;
  font-weight: bold;
}

.leftdiv {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.rightdiv {
  color: #0048ff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: -10px;
  border-bottom: 1px solid #0048ff;
}

.margin-pecentage {
  margin: 0px 20%;
  background-color: white;
  padding: 10px 15px !important;
  border-radius: 8px;
}

.headingflightPassenger {
  padding: 12px 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  background: #dfe6f7;
}

.headingflightPassenger p {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headingflightPassenger span {
  font-weight: 600;
}

.headingflightPassenger-new {
  padding: 12px 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--rounded, 4px);
  background: rgba(231, 60, 52, 0.15);
}

.headingflightPassenger-new p {
  color: #E73C34;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headingflightPassenger-new span {
  font-weight: 600;
}



.BaggageSector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BaggageSector > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.BaggageSector p {
  color: #000;
  font-size: 18.15px;
  font-family: Montserrat;
  font-weight: 400;
}

.BaggageSector span {
  color: #000;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  background-color: rgba(231, 60, 52, 0.15);
  padding: 8px 16px;
  border-radius: 6px;
}

.listBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.listBox > div p {
  color: black;
  font-size: 18.15px;
  font-family: Montserrat;
  font-weight: 400;
}

.listBox > div li {
  color: black;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  /* background-color: #DFE6F7;
    padding: 8px 16px;
    border-radius: 6px; */
}

.htmlFare {
  color: #000;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
}

.htmlFare li {
  color: #000;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
}

.fareRuleleft p {
  color: #000;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  background-color: rgba(231, 60, 52, 0.15);
  padding: 7px;
  border-radius: 6px;
  margin-bottom: 0;
}

.fareRuleleft span {
  color: #21325d;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.fareRuleleft > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
  margin-bottom: 10px;
}

.accor_dian .accordion {
  border-color: #21325d !important;
}

.leftDetBut {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftDetBut button {
  color: white;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 600;
  padding: 10px 30px;
  background-color: #21325d;
  border-radius: 8px;
  border: 2px solid #21325d;
}
.alert_passenger {
  position: fixed;
  top: 100px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999999999999999999999999;
}


.leftDetBut-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftDetBut-new button {
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: var(--rounded, 4px);
  background: #E73C34;
}


.baggagewrapper{

}

.ssrc-sctive{
  background-color: rgba(231, 60, 52, 0.15);
    color: #000;
    width: fit-content;
    font-weight: 700;
    height: 60px;
    padding: 15px;
    font-size: calc(var(--font-scale, 1)*16px);
    justify-content: flex-start;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ececec;
    cursor: pointer;
    margin-right: 10px;
}


.ssrc-sctive{
  gap: 12px;
}


.appendBottomssrc{
 margin-bottom: 20px;
}

.appendBottomssrcheading span{
 color: #249995;
}

.baggagelist{
  max-height: 250px;
    overflow-y: auto;
    margin: 0 -30px;
}

.extrabaggagesection{
padding: 0 30px;
margin-bottom: 16px;
position: relative;
}

.baglistItem{
  padding: 16px 0;
  color: #4a4a4a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  position: relative;
}

.paravaluessrc{
  display: flex;
  align-items: center;
}


.finalrpicessrc{
font-size: calc(var(--font-scale, 1)*16px);
vertical-align: middle;
display: inline-block;
margin-right: 15px;
width: auto;
margin-left: 15px;
color: #000;
}

.plusaddssr{
  cursor: pointer;

}

.spinnerssr {
  margin-top: 12px;
  border: 5px solid rgba(231, 60, 52, 0.15);
  border-left-color: #333; /* Change color as needed */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Adjust animation speed */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.ssrcbutton{
display: flex;
    border-radius: 35px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .22);
    cursor: pointer;
    color: #000;
    align-items: center;
    background-color: rgba(231, 60, 52, 0.15);
    justify-content: center;
    text-transform: uppercase;
    height: 32px;
    padding: 0 20px;
    border: none;
    outline: none !important;
    font-weight: 900;
}
.box_one {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  opacity: 1;
}

.mid-headers {
  border-radius: 4px;
  border: 1px solid #BBB;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 900px;
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}


.flightDetButton {
  display: flex;
  justify-content: center;
  align-items: center;

}

.flightDetButton button {
  color: white;
  font-size: 21.21px;
  font-family: Montserrat;
  font-weight: 600;
  word-wrap: break-word;
  padding: 12px 34px;
  background-color: #E73C34;
  border-radius: 7px;
  transition: all 0.2s;
  border: 2px solid #E73C34;
}
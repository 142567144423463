.btn {
    color: #707070;
    border: none;
    background-color: #fff;
    margin-right: 10px;
    box-shadow: 1px 2px 9px grey;
    border-radius: 20px;
    padding: 7px 28px;
    font-size: 10px
}

.btn-1 {
    border: none;
    background-color: #fff;
    margin-right: 10px;
    box-shadow: 1px 2px 9px grey;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    padding: 7px 28px;
    color: #707070;
    font-size: 10px
}

.input_value {
    border: 1px solid #70707059;
    border-radius: 10px;
}

.input_date {
    width: 100%;

    border-radius: 10px;
    font-size: 14px;
    padding: 8px;
}



.click {

    border-radius: 10px;
    background-color: #006FFF;
    color: #fff;
    padding: 5px 25px;
    border: none;
    box-shadow: 1px 2px 9px grey
}

.filter-container {

    width: 100%;
}

.input {
    margin-top: 8px;
    width: 300px;
}

/* Add more styles for checkboxes, buttons, etc. */

.fareFilter>div {
    align-items: center;
}


.serviceFilterOne {
    display: flex;
    flex-wrap: wrap;
}



.serviceFilterOne>div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}



.demographicFilter p {
    color: black;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 10px !important;
}



.demographicButton {
    display: flex;
    flex-direction: row;
    gap: 13px;
}


.demographicButton button {
    background-color: #21325d;
    color: white;
    font-weight: 600;
    font-size: 13px;
    font-family: "Montserrat";
    border: 2px solid #21325d;
    padding: 8px 22px;
    border-radius: 7px;
}

.Request>div {
    display: flex;
    flex-direction: column;
}

.Request p {
    color: black;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 10px !important;
}


.serviceButtonBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    align-items: center;
    margin-top: 20px;

}

.serviceButtonBox button {
    background-color: #21325d;
    color: white;
    font-weight: 600;
    border: 2px solid #21325d;
    padding: 8px 23px;
}
.main-container {
  background: white;
  padding: 2rem;
  margin: auto;
  width: 100%;
  height: 1000px;
  border-radius: 8px;
  margin-top: 100px;
}

.header_queue {
  /* width: 942px; */
  height: 84px;
  background: #21325D;
  box-shadow: 0px 4px 8px 0px #00000029;
  margin: auto;
  margin-top: -75px;
  border-radius: 8px;
}

.header_queue h5 {
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 31px;
  padding-left: 30px;
  margin: auto;
}

.main-search {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  align-items: center;
  align-content: stretch;
  flex-wrap: nowrap;

}

.heading {
  width: 1009px;
  height: 49px;
  top: 551px;
  left: 46px;
  padding: 10px, 20px, 10px, 20px;
  border-radius: 4px;
  gap: 10px;
  background: #DFE6F7;
}

.heading h3 {
  font-family: Montserrat;
  font-size: 24px;
  padding: 10px;
  /* font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left; */

}

.heading-new {
  width: 1009px;
  height: 49px;
  top: 551px;
  left: 46px;
  padding: 10px, 20px, 10px, 20px;
  border-radius: 4px;
  gap: 10px;
  background: rgba(231, 60, 52, 0.15);
}

.heading-new h3 {
  font-family: Montserrat;
  font-size: 24px;
  padding: 10px;
  /* font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left; */

}



.searchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.75rem;
  border-radius: 1rem;
  background: #DFE6F7;
  margin-left: 2rem;
}

.searchbar input {
  flex: 1;
  border: none;
  font-size: 1.5rem;
  font-family: var(--font-raleway);
  font-weight: 500;
  padding-right: 1rem;

  outline: none;
  color: #a1a1a1;
  background: #DFE6F7;
}

.searchbar img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}



.searchbar-new {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.75rem;
  border-radius: 1rem;
  background: #DFE6F7;
  margin-left: 2rem;
}

.searchbar-new input {
  flex: 1;
  border: none;
  font-size: 1.5rem;
  font-family: var(--font-raleway);
  font-weight: 500;
  padding-right: 1rem;

  outline: none;
  color: #a1a1a1;
  background: #DFE6F7;
}

.searchbar-new img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}


/* FlightBookings.css */
.cancel-container {
    padding-top: 80px; /* Adjust this value based on the top bar's height */
    margin: 0 auto;
    max-width: 1200px;
    overflow-x: scroll;
    position: relative;}
  
  /* Entire table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th,
  table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  table th {
    background-color: #f0f0f0;
  }
  
 
  .cancelButton{
    margin: 5px;
    border: none;
    padding: 1px 10px;
    border-radius: 4px;
    background-color: rgb(20, 20, 20);
    color: #b98423;
    cursor: pointer;
    justify-content: right;
  }
  button:hover {
    background-color: rgb(72, 72, 72);
  }
  
  /* Active page button */
  button.active {
    background-color: #97eaef;
  }



.filterBox {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c9caca;
  gap: 20px;
  color: #21325d;
  background-color: #fff;
}

.filterBox p {
  color: #000;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterBox label {
  color: #21325d;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filterBox select {
  padding: 4px !important;
}

.book-btn {
  background-color: #21325d;
  color: white;
  padding: 8px 12px;
}

.hotelResultBox {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
  margin-top: 24px;
}

/* .outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
} */

.ModifySearch {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background: rgb(241, 241, 241);
  /* box-shadow: 0px 0px 24px -11px; */
}

.outerFilterBox {
  padding: 10px;
  border-radius: 10px;
  background: rgb(241, 241, 241);
}

.searchDestination b {
  text-transform: capitalize;
}

.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.package_input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 3px solid #dfdfdf;
  box-shadow: 0px 1px 6px #00000029;
}

.package_input::placeholder {
  color: #4e4c4ca1;
  font-size: 14px;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  padding-left: 10px;
}

.package_lable {
  color: #4e4c4c;
  font-size: 15px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  top: 13px;
  left: 15px;
  padding: 0% 7px;
  position: relative;
  background-color: #fcffff;
}

.package_select {
  width: 100%;
  border: 10px;
  border-radius: 10px;
  height: 45px;
  border: 3px solid #70707069;
}

.packageResult {
  /* background-color: white; */
  width: 100%;
  /* overflow: hidden; */
}

.packageResult .container-fluid {
  background-color: transparent !important;
  padding: 0 !important;
}

.bookDel .container {
  background-color: white;
  padding: 15px;
}

.packageResultBox {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  /* padding: 15px; */
  border-radius: 8px;
  border: 1px solid lightgray;
  background: #fffbfb;
  margin: 10px 0px;
  cursor: pointer;
  flex-wrap: nowrap;
}

.packageResultBox p {
  margin-bottom: 0 !important;
}

.packageImage {
  padding: 15px;

  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.packageImage img {
  width: 250px;
  height: 220px;
}

.packageResultDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  padding: 15px;
}

.icon-box {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.icon-box > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.priceBook {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  border-left: 1px solid lightgray;
}

.priceBookOne {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  gap: 12px;
  align-items: center;
  padding: 40px 0px;
}

.priceBookOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceBookOne p {
  color: #e73c33;

  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.priceBookOne h3 {
  color: #fff;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 16px;
  background: #071c2c;
  display: inline-block;
}

.priceBookOne h4 {
  color: #071c2c;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.priceBookOne h4 svg {
  font-size: 13px !important;
}

.priceBookOne b {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priceBookTwo button {
  padding: 11.28px 28.199px;
  border-radius: 5.64px;
  font-weight: 600;
  background: #21325d;
  color: #fff;
  border: 2px solid #21325d;
  transition: 0.3s all;
}

.priceBookTwo button:hover {
  background: transparent;
  color: #21325d;
}

.packageTitle {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.customize {
  color: #e73c33;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.departure {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;
}

.icon-box p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.destination ul {
  padding-left: 1rem !important;
}

.destination ul li {
  color: #bbb;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 3px 0px;
}

.seeMore button {
  color: #0048ff;

  font-family: Montserrat;
  font-size: 16.919px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
}

.packageResult .container-fluid {
  padding: 0px 20px !important;
}

/* style= {
        {
        width: 361,
         height: 642,
          background: 'white',
           borderRadius: 8, 
           border: '1px #5C85A4 solid', 
           padding: "10px"
    }
} */

.packResFilterBox {
  width: 100%;
  /* height: 100%; */
  /* margin: 10px 0px; */
  /* margin: 10px 0px; */
  border-radius: 8px;
  border: 1px solid #5c85a4;
  background: #fff;
  padding-top: 8px;
  margin-bottom: 10px;
}

.icon-box-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 850px) {
  .packageImage {
    flex: 100% !important;
  }

  .packageResultDetails {
    flex: 50% !important;
    width: 50%;
    margin-top: 20px;
  }

  .priceBook {
    flex: 20% !important;
    margin-top: 20px;
  }

  .filterBox p {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
  }

  .filterBox {
    gap: 12px;
  }
}

.customize-new {
  color: #e73c33;
  border: 1px solid #e73c33;
  font-family: Montserrat;
  border-radius: 4px;
  font-size: 10px;
  font-style: normal;
  padding: 4px;
  width: fit-content;
  font-weight: 600;
  line-height: normal;
}

.destination-new ul {
  padding-left: 1rem !important;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.destination-new ul li {
  color: #bbb;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 3px 0px;
}

.priceBook-new {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
}

.show-more-package-new {
  display: flex;
  color: #e73c34;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 14.1px;
  border-radius: 5.64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
  align-self: stretch;
}

.confirmRect {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 9px;
    background: #eaf7fa;
    border-radius: 8px;
    margin: 14px 0px;
    padding: 15px;
}

.confirmRect>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 9px;
}

.confirmRect p {
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    word-wrap: break-word
}

.confirmRect span {
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    word-wrap: break-word
}





.fligtConfirmDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #DFE6F7;
    border-radius: 8px;
    margin: 14px 0px;
    padding: 0px 15px;
}

.fligtConfirmDetails>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    padding: 15px 0px;

}


/* .fligtConfirmDetails>div:not(:last-child) {
    border-right: 1px solid #999;
} */


.fligtConfirmDetails span {
    color: black;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 600;
}

.fligtConfirmDetails p {
    color: black;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
}


.passengerData {
    padding: 23px 34px;
}


.headingflightPassengerConfirm {
    margin-top: 10px;

    padding: 8px 15px;
    border-radius: 7px 7px 0px 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background: rgba(187, 187, 187, 0.30);
}

.headingflightPassengerConfirm span {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.passengerData {
    padding: 23px 34px;
    border-radius: 0px 0px 7px 7px;
    border-left: 1px solid #BBB;
    border-right: 1px solid #BBB;
    border-bottom: 1px solid #BBB;
    display: flex;
    justify-content: space-between;

}

.passengerData>div {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.passengerData span {
    color: #000;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.passengerData p {
    color: #000;

    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
/* CreateAdvertisementForm.css */

.advertisement-container {
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: #1b77c8;  */
  /* Light Blue background color */
  /* background-image: url('your-image-url.jpg'); /* Add your background image URL here */
  background-size: cover;
  background-repeat: no-repeat;
}

.advertisement-form {
  max-width: 900px;
  padding: 20px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-top: -60px;
  background-color: #fff;
  /* LightBlue background color with some transparency */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle box shadow */
  backdrop-filter: blur(8px);
  /* Add a glass effect */
  animation: fadeIn 1s ease;
  /* Add fade-in animation */
  -webkit-animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.form-label-add {
  display: block;
  margin-bottom: -10px;
  font-weight: bold;
  color: #090808;
  /* Darken label text color */
}

.form-label-image {
  display: block;

  font-weight: bold;
  color: #0a0a0a;
}



.form-input-ads {
  width: 100%;
  padding: 10px;

  box-sizing: border-box;
  border: 1px solid #ccc;
  /* Add a border for a cleaner look */
  border-radius: 4px;
  background-color: #fff;
  /* White background color */
}

.form-button {
  width: 100%;
  padding: 10px;

  box-sizing: border-box;
  background-color: #21325D;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Add smooth transition */
}

.form-button:hover {
  background-color: #242fce;
}

.form-textarea {
  width: 100%;
  padding: 10px;

  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.headersagentsub{
  background-color: #E73C33;
}
.subadmin{
  background-color: #E73C33;
}


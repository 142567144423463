.priceSummary {
  border-radius: 8px;
  /* border: 1px solid #5c85a4; */
  background: #fff;
  padding: 16px;
}

.head {
  border-radius: var(--rounded, 4px);
  background: rgba(231, 60, 52, 0.8);
  padding: 10px;
}

.head span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hotName {
  margin: 12px 0px;
}

.hotName p {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}

.priceChart {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

p {
  margin-bottom: 0 !important;
}

.priceChart > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.priceChart > div:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.totCOmm {
  display: flex;
  flex-direction: column;
  /* background: #dfe6f7; */
  gap: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

.totCOmm > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TotGst {
  display: flex;
  flex-direction: column;
  border-radius: var(--rounded, 4px);
  background: rgba(231, 60, 52, 0.8);
  padding: 10px;
  gap: 5px;
}

.TotGst > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.text-bold {
  font-weight: 600;
}

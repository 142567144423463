@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

.containersubadmin {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screensub {
  background: linear-gradient(90deg, #e73c33, #e94f47);
  position: relative;
  height: 500px;
  width: 360px;
  box-shadow: 0px 0px 24px #e59f9b;
}

.screen__contentsub {
  z-index: 1;
  position: relative;
}

.screen__backgroundsub {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 400px;
  width: 520px;
  background: #fff;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #e94f47;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #cf2118, #ea524a);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 80px;
  width: 200px;
  background: #e73c33;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.loginsubadmin {
  width: 320px;
  padding: 30px;
  padding-top: 156px;
}

.login__fieldsub {
  padding: 20px 0px;
  position: relative;
}

.login__iconsub {
  position: absolute;
  top: 30px;
  color: #e73c33;
}

.login__inputsub {
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 75%;
  transition: 0.2s;
}

.login__inputsub:active,
.login__inputsub:focus,
.login__inputsub:hover {
  outline: none;
  border-bottom-color: #e73c33;
}

.login__submitsub {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #e73c33;
  box-shadow: 0px 2px 2px #e73c33;
  cursor: pointer;
  transition: 0.2s;
}

.login__submitsub:active,
.login__submitsub:focus,
.login__submitsub:hover {
  border-color: #e73c33;
  outline: none;
}

.button__iconsub {
  font-size: 24px;
  margin-left: auto;
  color: #e73c33;
}

.error-message {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.error-message p {
  margin: 0;
}

.toast {
  align-items: center;
}

.Toastify__toast-container {
  position: fixed;
  z-index: 9999;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .mainsubadmin {
  background-image: image("../../Images/login-01.jpg");
} */

/* body {
  height: 100%;
  width: 100%;
  background-color: lightgray;
}

.loginContainer {
  background-color: #21325D;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainerInner {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  max-width: 950px;
  height: 450px;
  margin: 0px 25px;

}



.loginForm {
  flex: 1;
  background-color: white;
  padding: 25px;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm input {
  height: 50px;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid #21325D;
  border-radius: 8px;
  width: 100%;
}

.loginForm>div {
  margin-bottom: 20px;
}

.loginForm>div>div {
  margin-bottom: 20px;
}

.loginForm>div>div label {
  margin-bottom: 8px;
}

.loginImg {
  flex: 2;
  height: 450px;
  position: relative;
}

.loginImg img {
  width: 100%;
  height: 100%;
  border-radius: 12px 0px 0px 12px;
}



.loginImg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0242e381;
  z-index: 1;
  border-radius: 12px 0px 0px 12px;
}

.overlay {
  position: absolute;
  z-index: 2;
  color: white;
  top: 35%;
  left: 10%;
}

.overlay h3 {
  font-size: 50px;
  font-weight: 900;
  font-family: 'Montserrat';
}



.loginForm p {
  color: #000;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginForm label {
  color: #071C2C;

  text-align: center;
  font-family: 'Montserrat';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mailBox {
  position: relative;
}

.mailBox .mailIcon {
  position: absolute;
  left: 10px;
  color: #21325D;
  top: 55%;
}


.passwordBox {
  position: relative;
}

.passwordBox .passIcon {
  position: absolute;
  left: 10px;
  color: #21325D;
  top: 55%;
}


.abs-logo {
  position: absolute;
  left: 28%;
  top: 0;
  z-index: 3;
  width: 300px;
}


.reges {
  color: #ddd;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}




@media screen and (min-width: 600px) and (max-width: 700px) {

  .loginContainerInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 950px;
    margin: 40px;
    height: 100%;

  }

  .loginContainer {
    background-color: #21325D;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .loginImg {
    margin: 0px 60px;
  }

  .loginImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
  }

  .loginImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0242e381;
    z-index: 1;
    border-radius: 12px 12px 0px 0px;
  }

  .overlay h3 {
    font-size: 32px;
    font-weight: 900;
    font-family: 'Montserrat';
    text-align: center;
  }

  .overlay {
    left: 1%;
    top: 45%;
  }

  .loginForm {
    position: relative;
    margin-top: -26px;
    border-radius: 12px;
    z-index: 5;
  }

  .abs-logo {
    position: absolute;
    left: 16%;
    top: 0;
    z-index: 3;
    width: 300px;
  }
}



@media screen and (min-width: 750px) and (max-width: 800px) {

  .loginContainerInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 950px;
    margin: 40px;
    height: 100%;

  }

  .loginContainer {
    background-color: #21325D;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .loginImg {
    margin: 0px 60px;
  }

  .loginImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
  }

  .overlay {
    left: 5%;
    top: 36%;
  }

  .overlay h3 {
    font-size: 36px;
  }


  .loginForm {
    position: relative;
    margin-top: -146px;
    border-radius: 12px;
    z-index: 5;
  }

}



@media screen and (min-width: 700px) and (max-width: 750px) {

  .loginContainerInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 950px;
    margin: 40px;
    height: 100%;

  }

  .loginContainer {
    background-color: #21325D;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .loginImg {
    margin: 0px 60px;
  }

  .loginImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
  }

  .loginImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0242e381;
    z-index: 1;
    border-radius: 12px 12px 0px 0px;
  }

  .overlay h3 {
    font-size: 32px;
    font-weight: 900;
    font-family: 'Montserrat';
    text-align: center;
  }

  .overlay {
    left: 1%;
    top: 45%;
  }

  .loginForm {
    position: relative;
    margin-top: -26px;
    border-radius: 12px;
    z-index: 5;
  }
}
 */

body {
  height: 100%;
  width: 100%;
  /* background-color: #f1f1f1; */
  background-color: #f1f1f1;
  /* background-color: lightgray; */
}

.hero-section-one {
  position: relative;
  /* padding-top: 130px; */
  /* padding-bottom: 300px; */
  overflow: hidden;
}

.hero-one-text {
  position: relative;
  z-index: 1;
}

.hero-one-text h1 {
  font-weight: 700;
  color: #fff;
  font-size: 50px;
}

.hero-one-text h5 {
  font-size: 22px;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 50px;
  color: #fff;
}

.hero-one-text .btn {
  margin-right: 16px;
}

.hero-section-one::before {
  content: "";
  position: absolute;
  /* width: 135%;
  height: 100%; */
  /* border-radius: 50%; */
  /* border-top-left-radius: 0; */
  /* border-top-right-radius: 0; */
  /* top: -8%; */
  /* left: -19%; */
  z-index: -1;
  /* background: linear-gradient(90deg, #ef326f, #fe6c4e); */
  /* background-image: url(./images/newBackGround.jpg) no-repeat right top; */
  background-image: url(../../Images/heroimg.png) !important;
  /* background-color: white; */
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
  /* object-fit: cover; */
  /* box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1); */
  /* -webkit-box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1); */
  /* -moz-box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1); */
}

/* .hero-section-one:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 245px;
  height: 245px;
  border: 40px solid #d904279d;
  z-index: -1;
  top: 14%;
  left: -12%;
} */

.hero-one-img img {
  position: absolute;
  right: 13%;
  top: 9%;
  /* animation: 30s infinite animation02; */
}

.hero-one-text .btn.two {
  background: transparent;
  border: 1px solid #fff;
  height: 58px;
}

.hero-one-text .btn.two:hover {
  background-color: white;
  color: #000;
}

.hero-section-one .login-form {
  width: 82%;
  background-color: #c0c0c02e !important;
  border-radius: 15px;
  position: relative;
  z-index: 1;

  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.hero-section-one .login-form button {
  /* background: linear-gradient(90deg, #ef326f, #fe6c4e); */
  background: linear-gradient(90deg, #d90429, #d904279c);
  border: 2px solid #ef326f;
}

.afford p {
  background-color: white;
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
}

.hero-one-text-new {
  color: #071c2c;

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 67.2px */
}

.hero-one-text-new span {
  color: #1e429f;

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

/* services section  */

.gap {
  padding: 120px 0;
}

.headingBusi h2 {
  /* font-weight: 800; */
  font-size: 44px;
  font-family: Montserrat;
}

.section-business h3 {
  font-weight: 800;
  position: absolute;
  color: #f9efef;
  text-wrap: nowrap;
  font-size: 110px;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.section-business .headingBusi.two {
  position: relative;
  width: 100%;
  padding-bottom: 40px;
}

.section-business,
.business-img {
  position: relative;
}

.better-business {
  position: relative;
  /* padding-left: 100px; */
}

.better-business p {
  width: 90%;
  padding-bottom: 36px;
}

.count-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 150px;
  justify-content: space-between;
  box-shadow: 0px 0px 13px 0px rgba(241, 60, 107, 0.3);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(241, 60, 107, 0.3);
  -moz-box-shadow: 0px 0px 13px 0px rgba(241, 60, 107, 0.3);
}

.count-text:before {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background-color: #21325d;
  opacity: 0.3;
  padding-top: 36px;
  position: absolute;
  content: "";
  top: 5px;
  left: 5.5px;
}

.count-text {
  position: relative;
  display: flex;
  width: 220px;
  height: 220px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(90deg, #d90429, #d904279c);
  /* padding-top: 36px; */
}

.count-text p {
  transform: translateY(-30px);
  line-height: 24px;
  font-size: 14px;
  width: 75%;
  padding-bottom: 0;
}

.count-text sup {
  font-size: 40px;
  font-weight: bold;
  color: #000;
  line-height: 0;
  padding-top: 44px;
}

.count-text h2 {
  font-size: 60px;
  font-weight: 800;
  position: relative;
}

img.dots-shaps {
  position: absolute;
  right: -20%;
  top: 50%;
}

.business-img {
  position: relative;
}

img.dots-shaps {
  position: absolute;
  animation: top 5s infinite;
}

.section-business {
  position: relative;
}

.section-business:before {
  position: absolute;
  content: "";
  height: 360px;
  width: 628px;
  border-radius: 300px;
  background: linear-gradient(90deg, #d90429, #d904279c);
  bottom: 218px;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.business-img img {
  width: 100%;
}

img.dots-shaps {
  width: 10%;
}

.together-gap {
  padding: 70px 0;
}

.together h2 {
  font-size: 50px;
  font-family: Montserrat;
}

.together p {
  width: 80%;
}

ul.list {
  margin-bottom: 30px;
}

ul.list li {
  padding-top: 12px;
  font-size: 16px;
}

ul.list li img {
  padding-right: 15px;
}

.together .btn {
  padding: 17px 46px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;

  transition: all 0.4s ease-in-out;
  background: linear-gradient(to bottom left, #d90429, #d904279c);
  text-transform: capitalize;
}

.loginSign button {
  padding: 17px 46px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 32px;
  background: #e73c34;
  transition: all 0.4s ease-in-out;
  /* background: linear-gradient(to bottom left, #d90429, #d904279c); */
  text-transform: capitalize;
}

.loginNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  position: relative;
  z-index: 1;
}

.together ul {
  padding-left: 0 !important;
}

.together ul li {
  list-style-type: none;
}

.togetherFirst {
  position: relative;
  display: flex;
  justify-content: center;
}

.togetherFirst::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 78%;
  border-radius: 40px;
  background: linear-gradient(to right bottom, #d90429, #d904279c);
  bottom: 0;
  z-index: -1;
}

.together-img {
  display: flex;
  justify-content: center;
}

.together-img img {
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .count-text {
    width: 150px;
    height: 150px;
  }

  .count-text:before {
    width: 140px;
    height: 140px;
  }

  .count-text img {
    width: 150px;
    height: 150px;
  }

  .count-style {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    background-color: #fff;
    padding: 20px 37px;
    border-radius: 80px;
  }

  .section-business:before {
    position: absolute;
    content: "";
    height: 360px;
    width: 485px !important;
    bottom: 146px;
  }

  .together-img img {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 80%;
  }

  .together-img {
    display: flex;
    justify-content: center;
  }

  .hero-one-text h1 {
    font-size: 35px;
  }

  .hero-section-one {
    position: relative;
    /* padding-top: 82px; */
    /* padding-bottom: 296px; */
  }

  .section-business h3 {
    font-weight: 800;
    font-size: 87px;
    top: -6%;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .hero-one-text h1 {
    font-size: 25px;
  }

  .hero-one-text h5 {
    font-size: 14px;
  }

  .hero-section-one {
    position: relative;
    /* padding-top: 44px; */
    /* padding-bottom: 242px; */
  }

  .hero-section-one:before {
    content: "";
    position: absolute;
    /* width: 135%; */
    /* height: 100%; */
    /* border-radius: 50%; */
    /* border-top-left-radius: 0; */
    /* border-top-right-radius: 0; */
    /* top: -12%; */
  }

  .hero-section-one:after {
    left: -23%;
  }

  .section-business h3 {
    font-weight: 800;
    font-size: 67px;
  }

  .count-text {
    width: 100px;
    height: 100px;
  }

  .count-text:before {
    width: 95px;
    height: 95px;
  }

  .count-text img {
    width: 100px;
    height: 100px;
  }

  .count-style {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    background-color: #fff;
    padding: 20px 37px;
    border-radius: 80px;
  }

  .together-gap {
    padding: 20px 0;
  }

  .together-img img {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 60%;
  }

  .together h2 {
    font-size: 25px;
  }

  .hero-section-one .login-form {
    width: 100%;
    border-radius: 15px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  }

  .section-business:before {
    position: absolute;
    content: "";
    height: 360px;
    width: 404px !important;
    bottom: 96px;
  }

  .headingBusi h2 {
    /* font-weight: 800; */
    font-size: 28px;
  }
}

/* ///////////////////////new css/////////////////// */

.Overseasonjourney {
  display: flex;
  padding: 64px;
  justify-content: space-between;
  align-items: center;
  background: var(--white, #fff);
}

@media only screen and (max-width: 1100px) {
  .Overseasonjourney {
    flex-direction: column;
    padding: 50px;
  }
}

.Overseasonjourneycontent {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}

.Overseasonjourneytext {
  display: flex;
  padding: var(--0, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 24px);
}

.Overseasonjourneytext1 {
  color: #04397b;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.Overseasonjourneytext2 {
  color: #071c2c;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
}

.overseasoncontent-grid {
  display: flex;
  padding: var(--0, 0px);
  align-items: flex-start;
  gap: 56px;
}

@media only screen and (max-width: 1100px) {
  .overseasoncontent-grid {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .overseasoncontent-grid {
    margin-left: 30px;
    flex-direction: column;
  }
}

.overseasoncontent-grid1 {
  display: flex;
  padding: var(--0, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}

.overseason-grid1 {
  display: flex;
  border-radius: 16px;
  background: #fffff4;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: var(--4, 16px) var(--8, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: -32px;
}

.servicecardjourneygrid {
  display: flex;
  width: 211px;
  padding: var(--0, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.service-card1 {
  color: var(--gray-700, #374151);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
}
.service-card-number {
  color: #071c2c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.country-date-content-country {
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.country-date-content-country > span {
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-style: normal;
  margin-top: 12px;
  font-weight: 400;
  line-height: 150%;
}
.share-icons-container {
  display: flex;
  gap: 12px;
  margin-top: 12px;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}
.share-icons-container > img {
  height: 50px;
  width: 50px;
}

.country-date-content {
  display: flex;
  width: 264px;
  padding: var(--0, 0px);
  align-items: flex-start;
  gap: var(--4, 16px);
  flex-direction: column;
}

.button-overseas-content {
  display: flex;
  padding: var(--25, 10px) var(--5, 20px);
  justify-content: center;
  color: #e73c34;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  align-items: center;
  gap: var(--2, 8px);
  border-radius: 4px;
  border: 1px solid #e73c34;
  background: #fff;
}

.service-icon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.overseason-grid2 {
  display: flex;
  border-radius: 16px;
  background: #f3faf6;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: var(--4, 16px) var(--8, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -32px;
}
.overseason-grid3 {
  display: flex;
  border-radius: 16px;
  background: #fff3ea;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: var(--4, 16px) var(--8, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -32px;
}

.overseasoncontent-grid2 {
  display: flex;
  margin-top: 18px;
  padding: var(--0, 0px);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 36px;
}

.note-member {
  display: flex;
  border-radius: 16px;
  background: #ffeff6;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: var(--4, 16px) var(--8, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -32px;
  flex-shrink: 0;
}

.membersdetail {
  display: flex;
  padding: var(--0, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.total-member {
  color: var(--gray-700, #374151);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
}

.total-member-no {
  color: #071c2c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.succesful-visa {
  display: flex;
  border-radius: 16px;
  background: #f3f3ff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: var(--4, 16px) var(--8, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -32px;
  flex-shrink: 0;
}

/* /////////////partner */

.patner-container {
  display: flex;
  padding: 34px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  border-radius: 36px;
  background: #1e429f;
}

.recognized-patner-value {
  display: flex;
  padding: var(--0, 0px);
  flex-direction: column;
  align-items: center;
  gap: var(--6, 24px);
}

.patner-heading {
  color: var(--white, #fff);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
}

.patner-para {
  color: #fcfdfd;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.1px;
}

.patner-logo {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  /* overflow-x: scroll; */
  gap: 12px;
}

.travel-content-heading {
  color: #04397b;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.travel-content-para {
  color: #071c2c;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.travel-content-holiday {
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.discover-button {
  color: var(--white, #fff);
  border-radius: 32px;
  background: #e73c34;
  display: flex;
  height: 48px;
  padding: 12px var(--6, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--25, 10px);
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.about-us-value {
  display: inline-flex;
  padding: 64px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  background: var(--white, #fff);
}

.heading1 {
  color: #071c2c;
  font-size: 40px;
  font-style: normal;
  padding-bottom: 30px;
  font-weight: 700;
  text-align: center;
}

.about-us-grid-1 {
  display: flex;
  padding: var(--6, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--6, 24px);
  border-radius: 16px;
  /* background: #f9ffff; */
  /* box-shadow: 0px 4px 4px 0px rgba(126, 126, 126, 0.25); */
}

.about-us-content-2 {
  color: #071c2c;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

.about-us-grid-content {
  display: flex;
  flex-direction: column;
  gap: 17px;
  justify-content: center;
  align-items: center;
}

.journeyconnect {
  color: var(--white, #fff);
  display: flex;
  /* height: 48px; */
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--25, 10px);
  border-radius: 32px;
  background: #e73c34;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}

.sign-up1 {
  color: #071c2c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.create-account {
  color: #e73c34 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important;
  text-decoration: none !important;
}

.loginbutton {
  border-radius: var(--rounded-lg, 8px);
  background: #e73c34 !important;
}

/* ////////////////////////conatct us///////////////////////////// */

.contactuscontainer {
  background-image: url("../../Images/signupimg.png");
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  /* background: var(--primary-800, #1E429F); */
  /* box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05); */
  backdrop-filter: blur(7.5px);
  padding: 40px;
}

.contentcontact {
}

.need-assistance {
  display: flex;
  height: 100% !important;
  justify-content: space-around !important;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 100px;
}
.need-assistance1 {
  color: var(--white, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}

.login-form-contact {
  border-radius: 12px;
  background: rgba(235, 245, 255, 0.5);
  padding: var(--4, 16px) var(--6, 24px);
  gap: var(--5, 20px);
}

.contact-button button {
  border-radius: var(--rounded-lg, 8px);
  background: #e73c34;
  display: flex;
  color: #071c2c;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  padding: var(--25, 10px) var(--5, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
}
.contact-button button:hover {
  border: 1px solid #e73c34 !important;
  color: #071c2c !important;
}

.need-assistance2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 32px);
}

.contact-mail {
  color: white;
}

/* ////////Patners////////////////////////// */
.partnerBox {
  padding: 60px 30px;
  /* background-color: #1e429f; */
}

.partner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-container h3 {
  text-align: center !important;
  width: 73% !important;
  /* margin: auto !important; */

  padding-bottom: 10px !important;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 1.7rem;
  margin-bottom: 30px;
}

.partner-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white;
  border-radius: 10px;
  width: 90% !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 650px) {
  .partner-slide img {
    width: 60%;
  }
}

/* Custom styles for Glider slider */

/* Container styling */
.glider-container {
  max-width: 1100px;
  /* Adjust the width as needed */
  margin: 0 auto;
}

/* Slide styling */
.glider-track {
  display: flex;
}

.advertise-container {
  margin-top: 30px;
}

.advertise-slide {
  padding: 10px 2px;
  box-sizing: border-box;
}

/* Image styling */
.advertise-slide img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* Optional: Add border-radius for rounded corners */
}

/* Title styling */
.advertise-slide .title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.advertise-container .slick-dots li button:before {
  font-family: "slick" !important;
  font-size: 6px !important;
  line-height: 20px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 20px !important;
  height: 20px !important;
  /* content: '•' !important; */
  background-color: #21325d;
  border-radius: 50%;
  text-align: center !important;
  opacity: 0.25 !important;
  color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eye-icon {
  position: "absolute";
  top: "50%";
  right: "10px";
  transform: "translateY(-50%)";
  cursor: "pointer";
}
.forgetBTN {
  position: absolute;
  bottom: -22px;
  right: 0px;
  color: blue;
  font-size: 12px;
  cursor: pointer;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after {
  background: radial-gradient(closest-side at 50% 12.5%, #f03355 96%, #0000) 50%
      0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #f03355 96%, #0000) 0 50%/80% 20%
      repeat-x;
}

/* HTML: <div class="loader"></div> */
.loaderCon {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #e73c34;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

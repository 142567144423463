/* .flightLoading {
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9999 !important;
    background-color: white !important;
    position: absolute;
} */


/* LoadingSpinner.css */
.flightLoading {
    position: fixed;
    /* position: relative; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #cceaf7;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999 !important;
    /* Ensure the spinner is above other content */
}

.flightLoading img {
    /* width: 500px; */
    margin-top: 100px;
    /* Adjust the size of the loading GIF */
    height: 500px;
    /* Adjust the size of the loading GIF */
}


/* HTML: <div class="loader"></div> */
.loader12 {
    width: 60px;
    aspect-ratio: 1;
    display: grid;
    grid: 50%/50%;
    color: #25b09b;
    --_g: no-repeat linear-gradient(currentColor 0 0);
    background: var(--_g),var(--_g),var(--_g);
    background-size: 50.1% 50.1%;
    animation: 
      l6-0   1.5s infinite steps(1) alternate,
      l6-0-0 3s   infinite steps(1);
  }
  .loader12::before {
    content: "";
    background: currentColor;
    transform: perspective(150px) rotateY(0deg) rotateX(0deg);
    transform-origin: bottom right; 
    animation: l6-1 1.5s infinite linear alternate;
  }
  @keyframes l6-0 {
    0%  {background-position: 0    100%,100% 100%,100% 0}
    33% {background-position: 100% 100%,100% 100%,100% 0}
    66% {background-position: 100% 0   ,100% 0   ,100% 0}
  }
  @keyframes l6-0-0 {
    0%  {transform: scaleX(1)  rotate(0deg)}
    50% {transform: scaleX(-1) rotate(-90deg)}
  }
  @keyframes l6-1 {
    16.5%{transform:perspective(150px) rotateX(-90deg)  rotateY(0deg)    rotateX(0deg);filter:grayscale(0.8)}
    33%  {transform:perspective(150px) rotateX(-180deg) rotateY(0deg)    rotateX(0deg)}
    66%  {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)}
    100% {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);filter:grayscale(0.8)}
  }

  .fullscreen {
    background-color: white;
    height: 100vh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


/* .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  } */
  
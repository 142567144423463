.headingReview {
    border-radius: 8px;
    background: #DBE5FF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 24px 48px;
}


.margin-pecentage {
    margin: 0px 5%;
    background-color: white;
    padding: 10px 15px;
    border-radius: 8px;
}

.headingReview p {
    color: #21325D;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.headingReview-new {
    border-radius: 8px;
    border: 2px solid #FFE7E7;
    background: #FFFBFB;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 24px 48px;
}

.headingReview-new p {
    color: #071C2C;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.busAllDetail {
    border-radius: 8px;
 
border: 2px solid #FFE7E7;
background: #FFFBFB;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.busAllDetail>div:not(:last-child) {
    border-right: 1px solid #FFE7E7;
}

.busAllDetail>div {
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.busAllDetail p {
    color: #071C2C;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.busAllDetail span {
    color: #071C2C;

    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



.passengerDetBox {
    padding: 24px 32px;
    border-radius: 8px;
    border: 2px solid #FFE7E7;
    background: #FFFBFB;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.passengerDetBox p {
    color: #071C2C;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.passengerDetBox span {
    color: #0048FF;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.passDetails {
    padding: 18px 36px;
    border-radius: 8px;
    border: 1px solid #777;
    display: flex;
    flex-direction: row;
    gap: 30px;
    background: #FFFBFB;
    margin-bottom: 15px;
}

.passDetails>div {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.passDetails p {
    color: #000;

    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.passDetails span {
    color: #000;

    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



.bookBus {
    display: flex;
    justify-content: center;
    align-items: center;

}

.bookBus button {
    padding: 20px 54px;
    border-radius: 8px;
    background: #21325D;
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 2px solid #21325D;
    transition: all 0.3s;
}



.bookBus-new {
    display: flex;
    justify-content: center;
    align-items: center;

}

.bookBus-new button {
    padding: 20px 54px;
    border-radius: 8px;
    border: 2px solid #E73C33;
    background: #E73C33;
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   
    transition: all 0.3s;
}


.CancelRulesBus th {
    background-color: #888;

}

.CancelRulesBus td {
    background-color: white;
    color: #21325D;
}
.top_head {
  padding: 32px 16px;
  border-radius: 12px;
  border: 0.5px solid #888;

  background: #fff;
}

.top_head-new {
  padding: 32px 16px;
  border-radius: 12px;
  background: #fff;
}

.main_tab {
  background: #cff5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
}

.outerseat,
.outerlowerseat {
  position: relative;
  width: auto;
  height: 50%;
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
}

.busSeatlft,
.busSeatrgt {
  /* border: 2px solid red; */
  height: 45%;
  width: auto;
}

.upper {
  position: relative;
}

.busSeat {
  display: block;
  flex-direction: column;
}

.seatcontainer {
  position: relative;
  align-self: center;
}

.acFilter button {
  border: 2px solid #21325d;
  padding: 6px 14px;
  color: #666;
  font-family: "Montserrat";
  background-color: transparent;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.acFilter button:hover {
  border: 2px solid #21325d !important;
}

.seatFilter button {
  border: 2px solid #21325d;
  padding: 6px 14px;
  color: #666;
  font-family: "Montserrat";
  background-color: transparent;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.seatFilter button:hover {
  border: 2px solid #21325d !important;
}

/* .notSelectedAC {
  border: 2px solid #21325D;
  padding: 6px 20px;
  color: #fff;
  font-family: 'Montserrat';
  background-color: #21325D;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.applyFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.applyFilter button {
  padding: 8px 22px;
  background-color: #21325d;
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
}

.px4 {
  margin: 0px 60px !important;
  background-color: transparent !important;
}

.busType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #d9d9d9;
  margin: 20px 0px 10px 0px;
}

.busType p {
  color: #000;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.titlePickup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  /* background: #D9D9D9; */
  margin: 20px 0px 10px 0px;
}

.pickUpBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #fff;
  margin: 20px 0px 10px 0px;
}

.pickUpBox-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  color: #071c2c;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #ffe7e7;
  background: #fffbfb;
  margin: 20px 0px 10px 0px;
}

.titlePickup p {
  color: #000;
  font-family: "Montserrat";
  font-size: 19px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.busResultBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 32px 16px;
  border-radius: 12px;
  border: 0.5px solid #888;
  background: #fff;
}

.busResultBox p {
  color: #000;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.busResultBox span {
  color: #000;

  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.busResultBox .busImage {
  max-width: 30%;
}

.busResultBox img {
  max-width: 90%;
}

.busResultBox button {
  padding: 8px 12px;
  border-radius: 4px;
  background: #21325d;
  color: #fff;

  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 2px solid #21325d;
  transition: all 0.3s;
  text-wrap: nowrap;
}

.busSearchOne {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.busSearchTwo {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.busSearchTwo > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.busSearchThree {
  display: flex;
  justify-content: center;
  align-items: center;
}

.busSearchFour {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.pickDropDetails {
  padding: 21px;
  border-radius: 8px;
  border: 0.5px solid #777;
  background: #fffbfb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.passengerHeading p {
  color: #000;
  margin-left: 15px;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.passengerHeading-new {
  border-radius: var(--rounded, 4px);
  background: rgba(231, 60, 52, 0.15);
  display: flex;

  padding: 12px 16px;
  align-items: center;
  gap: 8px;
}

.passengerHeading-new p {
  color: #e73c34;

  margin-left: 15px;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.btn-busPassenger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-busPassenger button {
  padding: 20px 54px;
  border-radius: 8px;
  background: #21325d;
  color: #fff;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 2px solid #21325d;
  transition: all 0.3s;
}
.buslodingBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-top: 2px solid #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.busResultBox-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 32px 16px;

  border-radius: 8px;
  border: 2px solid #ffe7e7;
  background: #fffbfb;
}

.busResultBox-new p {
  color: #000;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.busResultBox-new span {
  color: #000;

  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.busResultBox-new .busImage {
  max-width: 30%;
}

.busResultBox-new img {
  max-width: 90%;
}

.busResultBox-new button {
  padding: 8px 12px;
  border-radius: 4px;
  border-radius: 5.64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
  color: #e73c34;

  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s;
  text-wrap: nowrap;
}

.busType-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #ffe7e7;
  background: rgba(231, 60, 52, 0.15);
  margin: 20px 0px 10px 0px;
}

.busType-new p {
  color: #000;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn-busPassenger-new button {
  border-radius: var(--rounded, 4px);
  background: #e73c34;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8.952px;
  color: var(--white, #fff);
  font-family: Montserrat;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
}

.btn-busPassenger-new {
  display: flex;

  justify-content: center !important;
}


.btn-busPassenger-new button:hover {
  border-radius: var(--rounded, 4px);
  border:1px solid #e73c34;
  display: flex;
color: #000;

}

.seatTypeDetails {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center !important;
  gap: 15px !important;
  /* padding-left: 100px; */
}

.seatTypeDetails>div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.seatTypeDetails span {
  display: inline-block;
  height: 14px;
  width: 24px;
  border: 1px solid #111;
}
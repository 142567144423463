/* LoadingSpinner.css */
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure the spinner is above other content */
}

.loading-gif {
    width: 500px;
    /* Adjust the size of the loading GIF */
    height: 500px;
    /* Adjust the size of the loading GIF */
}
/* General Container for the form */
.holiday-add-images-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Title Style */
.form-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Form Styles */
.upload-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Checkbox Container Styles */
.checkbox-container {
  display: flex;
  flex-direction: column;
}

.checkbox-label {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.checkbox-input {
  margin-right: 10px;
}

/* File Input Section */
.file-input-container {
  display: flex;
  flex-direction: column;
}

.file-input-label {
  font-size: 1rem;
  margin-bottom: 8px;
}

.file-input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit Button */
.submit-btn {
  padding: 12px 20px;
  font-size: 1.1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  width: 200px;
}

.submit-btn:hover {
  background-color: #45a049;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add responsiveness for mobile */
@media (max-width: 600px) {
  .holiday-add-images-container {
    padding: 20px;
  }

  .upload-form {
    gap: 15px;
  }

  .submit-btn {
    width: 100%;
  }
}

.add-itinerary-btn {
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-itinerary-btn:hover {
  background-color: #45a049; /* Slightly darker green */
  transform: scale(1.05);
}

.add-itinerary-btn:active {
  background-color: #3e8e41; /* Even darker green */
  transform: scale(0.95);
}

.custom-row td {
  color: black; /* Text color set to black */
}

.custom-row {
  background-color: rgb(
    231,
    240,
    252
  ) !important; /* Set background color to red */
}

.custom-row td {
  color: black; /* Ensure text color is black */
}
.custom-table {
  width: 100%; /* Makes sure the table takes the full width of the container */
}

.ant-table {
  width: 100% !important; /* Ensures the Ant Design table also takes up full width */
}

/* Apply border to the entire table */
.custom-bordered-table .ant-table {
  border-radius: 4px; /* Optional: rounded corners */
}

/* Apply borders to header and row cells */
.custom-bordered-table .ant-table-thead > tr > th,
.custom-bordered-table .ant-table-tbody > tr > td {
  border: 1px solid #d9d9d9; /* Border color for individual cells */
}

/* Remove the bottom border from the last row to avoid an extra line */
.custom-bordered-table .ant-table-tbody > tr:last-child > td {
  border: 1px solid #d9d9d9;
}

/* Optional: Styling for the table header */
.custom-bordered-table .ant-table-thead > tr > th {
  background-color: #f7f7f7;
  font-weight: bold;
}

.holiday-add-images-container {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.upload-form {
  width: 100%;
}

.checkbox-container {
  margin-bottom: 16px;
}

.checkbox-label {
  font-weight: bold;
}

.submit-btn {
  width: 100%;
  margin-top: 20px;
}

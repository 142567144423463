:root {
  --main-bg-color: #161a30;
  --main-bg-color-secondery: #31304d;
  --main-bg-color-hover: #b6bbc4;
  --main-bg-color-text: #e6e5f0;
  /* --main-bg-color: #5B0888;
  --main-bg-color-secondery: #713ABE;
  --main-bg-color-hover: #9D76C1;
  --main-bg-color-text: #E5CFF7; */
  /* #5B0888 */
  /* 713ABE */
  /* #9D76C1 */
  /* E5CFF7 */
}

.agentProfileDasbordContainer {
  background-color: var(--main-bg-color) !important;
  width: 100%;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  height: 100%;
}
.agentProfileDasbordNavBar {
  width: 100%;
  height: 60px;
  color: var(--main-bg-color-text);
  padding: 0px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.MenuIconAgentProfile {
  /* animation: rotateMenuIcone 0.35 ease-in-out forwards;
  /* transition: all 0.35 ease-in-out; */ 
}
.rotate180 {
  /* transform: rotate(180deg); */
  /* animation: rotate0 1s ease-in-out forwards;
  animation-direction: alternate-reverse; */
}
.rotate0 {
  /* transform: rotate(0deg); */
  /* animation: ; */
  animation: rotate0 .35s ease-in-out forwards;
}

.agentProfileContentContainer {
  /* width: 100%; */
  display: flex;
  height: calc(100vh - 70px);
  background-color: var(--main-bg-color-text) !important;
  transition: all 1s ease-in-out;
}
.agentProfileContentLeft {
  background-color: var(--main-bg-color) !important;
}
.agentProfileContentLeftItem {
  color: var(--main-bg-color-text);
  display: flex;

  /* width: 200px; */
  height: 50px;
  align-items: center;
  border-radius: 8px;
  margin: 5px 10px;
}
.agentProfileContentLeftItem:hover {
  background-color: var(--main-bg-color-secondery) !important;
  color: var(--main-bg-color-text) !important;
}
.agentProfileContentLeftItemIcon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-bg-color-text) !important;
}
.agentProfileContentLeftItemIcon svg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-bg-color-text) !important;
  size: 25px !important;
}
.agentProfileContentLeftItemText {
  color: var(--main-bg-color-text);
  font-size: 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: 175px;
  transition: all 0.35s ease-in-out;
  white-space: nowrap;
}
.AgentProfileDropDwon {
  position: absolute;
  top: 70px;
  right: 44px;
  width: 200px;
  padding: 10px;
  background-color: var(--main-bg-color-hover) !important;
  border-radius: 4px;
  -webkit-box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
  -moz-box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
  box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
  animation: DropDownAnimation 0.35s ease-in forwards;
  z-index: 999;
}
.AgentProfileDropDwonRemove {
  animation: DropDownAnimationLeave 0.35s ease-in forwards;
  /* display: none !important; */
}
.AgentProfileDropDwonAgentDiv {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--main-bg-color-hover);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
  color: var(--main-bg-color);
}
.AgentProfileDropDwonAgentDiv:hover {
  background-color: #161a3021;
}
.AgentProfileDropDwonAgentDivItem {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 5px;
  color: var(--main-bg-color);
}
.AgentProfileDropDwonAgentDivItem:hover {
  background-color: var(--main-bg-color-text);
  color: #31304d;
  background-color: #161a3021;
}
.activeAgentProfile {
  /* background-color: var(--main-bg-color); */
  background-color: #0d6efd !important;

  color: var(--main-bg-color-secondery);
}
.activeAgentProfile div,
.activeAgentProfile svg {
  /* background-color: var(--main-bg-color) !important; */
  color: var(--main-bg-color-text) !important;
}
.activeAgentProfile div :hover,
.activeAgentProfile svg:hover {
  /* background-color: var(--main-bg-color) !important; */
  color: var(--main-bg-color-text) !important;
}
.activeAgentProfile {
  background-color: var(--main-bg-color-hover);
}
.agentProfileRight {
  background-color: var(--main-bg-color-text);
  border-radius: 12px;
  margin: 12px;
  margin-top: 0px;
}
.AgentHomeH1 {
  color: var(--main-bg-color);
  font-size: 34px;
  font-weight: 500;
  margin-top: 50px;
  margin-left: 50px;
}

.agentHomeDataContainer {
  display: flex;
  justify-content: start;
  gap: 20px;
  padding: 10px;
}
.agentNotAccess {
  color: var(--main-bg-color);
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.agentNotAccessDIV {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  align-content: space-around;
  height: 150px;
  background: var(--main-bg-color-hover);
  padding: 10px;
  border-radius: 8px;
  background-color: var(--main-bg-color-hover) !important;
  border-radius: 4px;
  -webkit-box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
  -moz-box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
  box-shadow: -4px 2px 19px -7px rgba(49, 48, 77, 1);
}
.agentHomeDataItem {
  width: 300px;
  background-color: var(--main-bg-color-hover);
  display: flex;
  justify-content: space-between;
  height: 150px;
  padding: 10px;
  border-radius: 8px;
}
.agentHomeDataItemleft {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.MenueFalse {
  width: 0px !important;
}
.AgentProfileDropDwonAgentDiv span {
  font-size: 25px;
}
.AgentProfileDropDwonAgentDivItem div {
  font-size: 20px !important;
}
@keyframes DropDownAnimation {
  0% {
    /* transform: translateY(0px); */
    top: 50px;
    opacity: 0;
    scale: 0.8;
  }
  100% {
    /* transform: translateY(100px); */
    top: 70px;
    opacity: 1;
    scale: 1;
  }
}
@keyframes DropDownAnimationLeave {
  0% {
    /* transform: translateY(100px); */
    top: 70px;
    opacity: 1;
    scale: 1;
  }
  100% {
    /* transform: translateY(0px); */
    top: 50px;
    opacity: 0;
    scale: 0.8;
    display: none;
  }
}
@keyframes rotateMenuIcone {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(350deg);
  }
}
@keyframes rotate180 {
  /* 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(350deg);
  } */
}
@keyframes rotate0 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

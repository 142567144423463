@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@300;400;500;600;700;800&display=swap");

.regouter_box {
  background-color: white !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  padding: 20px;
}

.registerContainer {
  padding-top: 20px;
  width: 60%;
  height: 100%;
  border-radius: 20px;
  /* background-size: 100% 100%; */
  border-radius: 16px;
  background: rgba(249, 255, 252, 0.6) !important;
  /* backdrop-filter: blur(20px); */
  padding: 25px;
  background-size: 100% 100%;
}

.flightNavBar {
  width: 100%;
  margin-top: 20px;
}

.flightNavBarContainer {
  width: 80%;
  height: auto;
  margin: 20px;
}

/* form input */

.form_input {
  height: 90% !important;
  position: relative !important;
  width: 100% !important;
}
.form_input_regestration {
  height: 90% !important;
  position: relative !important;
  width: 100% !important;
}
.form_input_regestration > #error1 {
  bottom: -14px;
  position: absolute;
  left: 1px;
}

.form_input input {
  height: 40px !important;
  padding-left: 4px 8px !important;
  /* padding-right: 45px !important; */
  border-radius: 8px !important;
  border: 1px solid #777 !important;
  font-weight: 700 !important;
  color: #777 !important;

  background: #fffbfb !important;
}
.form_input input :focus {
  border: 1px solid #5c85a4 !important;
  outline: none;
}
.form_input_regestration input {
  height: 40px !important;
  padding-left: 8px !important;
  /* padding-right: 45px !important; */
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  border: 1px solid #777 !important;
  font-weight: 700 !important;
  color: #777 !important;

  background: #fffbfb !important;
  width: 100%;
}
.form_input_regestration input :focus {
  border: 1px solid #5c85a4 !important;
}
.form_input_select {
  font-weight: 700 !important;
  color: #777 !important;
  border: 1px solid #777 !important;
  padding-right: 10px !important;
}

.form_input input::placeholder {
  font-weight: 700 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.form_input_regestration input::placeholder {
  font-weight: 700 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 13px !important;
}

.form_input .lable {
  font-size: 13px !important;
  color: #4e4c4c !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}
.form_input_regestration .lable {
  font-size: 13px !important;
  color: #4e4c4c !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.form_input .from_input::placeholder {
  /* color: #4e4c4ca1 !important; */
  /* font-size: 18px !important; */
  /* font-weight: 700 !important; */
  /* font-family: "Quicksand", sans-serif !important; */
  /* white-space: nowrap !important; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis !important; */
  color: #bbb !important;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.form_input_regestration .form_input_regestration::placeholder {
  /* color: #4e4c4ca1 !important; */
  /* font-size: 18px !important; */
  /* font-weight: 700 !important; */
  /* font-family: "Quicksand", sans-serif !important; */
  /* white-space: nowrap !important; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis !important; */
  color: #bbb !important;
  font-family: "Montserrat" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.form_lable {
  font-size: 13px !important;
  font-weight: 700 !important;
  font-family: "Montserrat", sans-serif !important;
  left: 10px !important;
  line-height: 14px !important;
  pointer-events: none !important;
  position: unset !important;
  transform-origin: 0 50%;
  top: 24px;
  transform: translateY(-6px) translateX(-1px) !important;
  background: unset !important;
  padding: 0px 4px;
  color: #000 !important;
}
.form_lable_regestration {
  font-size: 13px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  left: 10px !important;
  line-height: 14px !important;
  pointer-events: none !important;
  position: unset !important;
  transform-origin: 0 50%;
  /* top: 24px; */
  transform: translateY(-6px) translateX(-1px) !important;
  background: unset !important;
  padding: 0px 4px;
  color: #000 !important;
}

.form_lable1 {
  color: #4e4c4c !important;
  font-size: 13px !important;
  font-weight: Bold !important;
  /* margin-bottom: 1rem !important; */
}

.form_input_select {
  width: 100% !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: 1px solid #5c85a4 !important;
}

/*end  */

.input_size {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  z-index: 1;
}
.input_size:focus {
  border: 1px solid #5c85a4 !important;
  outline: none;
}
.Regestration_inputFilds_Container {
  width: 100%;
  align-items: start;
  margin-top: 10px;
}

.Regestration_box_container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 8px;
}

.hero-section-two {
  position: relative;
  /* padding-top: 130px; */
  padding-bottom: 155px;
  overflow: hidden;
}

.hero-section-two::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: -1;
  /* background: linear-gradient(90deg, #ef326f, #fe6c4e); */
  /* background-image: url(./images/newBackGround.jpg) no-repeat right top; */
  background-image: url(../../Images/Latest.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  /* object-fit: cover; */
  /* box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1);
  -webkit-box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1);
  -moz-box-shadow: 50px 50px 0px 0px rgba(237, 208, 215, 1); */
}

/* .hero-section-two::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 245px;
  height: 245px;

  border: 40px solid #d904279d;
  z-index: -1;
  top: 14%;
  left: -12%;
} */
.boxContainerRegstration {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: space-between;
}

.regestration_Submit_Button {
  /* background-color: #21325d; */

  background: #e73c34;
  width: 50%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  transition-property: all;
  transition-duration: 1s;
}
.regestration_Submit_Button:hover {
  background-color: #ffffff;
  width: 50%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #21325d;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  border: 1px solid #21325d;
}
.boxContainerRegstration_innerdiv {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.Regestration_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .hero-section-two {
    position: relative;
    /* padding-top: 82px; */
    padding-bottom: 135px;
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .hero-section-two {
    position: relative;
    /* padding-top: 44px; */
    padding-bottom: 135px;
  }

  .hero-section-two:before {
    /* content: "";
    position: absolute;
    width: 135%;
    height: 50%;
    border-radius: 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: -12%; */
  }
  .boxContainerRegstration {
    flex-direction: column;
  }
  .boxContainerRegstration_innerdiv {
    height: 200px;
  }

  .hero-section-two:after {
    left: -23%;
  }
}
@media (min-width: 900px) and (max-width: 1000px) {
  .hero-section-two {
    position: relative;
    /* padding-top: 44px; */
    padding-bottom: 135px;
  }

  .hero-section-two:before {
    content: "";
    /* position: absolute;
    width: 135%;
    height: 50%;
    border-radius: 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: -12%; */
  }

  .hero-section-two:after {
    left: -23%;
  }
}

/* //////////////new css/////////////////////// */

.Regestration_btn_div-new {
  display: flex;
  gap: 12px;
  /* /* align-items: center; */
  justify-content: end;
  padding-top: 12px;
  width: 100%;
}
.loaderREG {
  width: 175px;
  height: 25px;
  border-radius: 20px;
  color: #21325d;
  border: 2px solid;
  position: relative;
}
.loaderREG::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
@keyframes l6 {
  100% {
    inset: 0;
  }
}

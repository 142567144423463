.book_content {
  height: auto;
  align-items: center;
  text-align: center;
}

.main-txt {
  align-items: left;
  font-family: var(--bs-body-font-family);
  font-weight: bold !important;
  font-size: 20px;
  letter-spacing: 0px;
  color: #252525;
  width: "150px";
}

.third-txt {
  color: #252525;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-txt {
  color: #ff8900;
  font-family: var(--bs-body-font-family);
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue_btn {
  background: #006fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: white;
  opacity: 1;
}

.input_area {
  width: auto;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 0px 20px;
  border: none;
}

Input {
  text-decoration: none;
}

.offer_area {
  width: auto;
  height: auto;
  background: #dfe6f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  margin: 7px 0px 21px 0px !important;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.accordian_area {
  width: auto;
  height: auto;
  border-radius: 20px;
  opacity: 1;
  margin: 20px 32px !important;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
}

.acc_para {
  text-align: left;
  font: normal normal bold 16px/20px Quicksand;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.jacuzzy_img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.hotelBookNowHeader {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  /* border: 1px solid #5C85A4; */
  gap: 20px;
  color: #21325d;
  background-color: #dfe6f7;
}

.hotelBookNowHeader p {
  margin-bottom: 0 !important;
  font-weight: 700;
}

.hotelBookNowOuter {
  padding: 5px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
}

.availabilityInner {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.availabilityInner > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  padding: 6px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 24px -11px;
}

.availabilityInner > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  padding: 6px;
  border-radius: 4px;
  background-color: #dfe6f7;
}

.availabilityInner p {
  margin-bottom: 0 !important;
}

.availabilityInner-new {
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
}

.availabilityInner-new > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  padding: 6px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  /* background-color: #DFE6F7; */
}

.availabilityInner-new > div > div:hover {
  cursor: pointer;
  color: #e73c34;
  border-bottom: 2px solid #e73c34;
}

.availabilityInner-new > div > div > img:hover {
  color: #e73c34;
}

.availabilityInner-new p {
  margin-bottom: 0 !important;
}

.serach-hotel-discribe-new {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.hotelBookNowOuter-new {
  border-radius: 16px;
  border: 1px solid #ffe7e7;
  background: #fffbfb;
  padding: 12px;
}

/* design for accordian  */

.css-1cdy1wm-MuiButtonBase-root-MuiAccordionSummary-root {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  text-decoration: none !important;
  color: inherit !important;
  display: flex !important;
  min-height: 46px !important;
  padding: 0px 16px !important;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  background-color: rgba(0, 0, 0, 0.03) !important;
  flex-direction: row !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-color: rgba(231, 60, 52, 0.15) !important;
  /* border: 1px solid #21325D !important; */
}

.css-v84d5j-MuiSvgIcon-root {
  user-select: none !important;
  width: 1.5em !important;
  height: 1.5em !important;
  display: inline-block !important;
  fill: currentColor !important;
  flex-shrink: 0 !important;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  font-size: 0.9rem !important;
  color: white !important;
  background: #21325d !important;
  padding: 5px !important;
  border-radius: 50% !important;
}

.css-1sg501g-MuiAccordionDetails-root {
  padding: 16px 16px 16px 16px;
  padding: 16px;
  border-top: none !important;
  border-radius: 20px;
  box-shadow: none !important;
  margin-top: 20px;
}

.css-70qvj9 {
  display: flex !important;
  align-items: center !important;
  gap: 20px !important;
}

.roomTypeName {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.roomTypeName .first {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  color: #21325d;
}

.ratePlan {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  
 
}

.priceCheck {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 24px;
}

.priceCheck .second {
  padding: 7px 12px;
  background-color: #21325d;
  color: white;
  font-weight: 700;
  border-radius: 8px;
}

.text {
  text-align: left;
}

.price {
  font-weight: 700;
  font-size: 20px;
  color: #21325d;
}

/* .bookNowButton {
  padding: 8px 18px;
  background-color: #21325d;
  color: white;
  font-weight: 700;
  font-size: 18px;
  border: 2px solid #21325d;
  border-radius: 5px;
} */

.bookNowButton-new {
  padding: 8px 18px;
  /* background-color: #21325d; */
  
background: #E73C34;
  color: white;
  font-weight: 700;
  font-size: 18px;
  border: 2px solid #E73C34;
  border-radius: 5px;
}

.roomCompo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 15px;
  overflow-y: scroll;
}

.roomCompo::-webkit-scrollbar-track {
}

.roomCompo::-webkit-scrollbar {
  height: 10px;
}

/* .roomCompo div > div {
  width: 400px;
} */

.roomCompo-new {
  display: flex;
  border-radius: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 15px;
  overflow-y: scroll;
}

.roomCompo-new::-webkit-scrollbar-track {
}

.roomCompo-new::-webkit-scrollbar {
  height: 10px;
}

/* .roomCompo-new div > div {
  width: 100%;
} */

.hotelBookNowHeader-new {
  display: flex;
  padding: 5px var(--0, 0px);
  justify-content: space-evenly;
  align-items: center;
  gap: 29px;
}

.serach-hotel-discribe-new-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #071c2c;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.serach-hotel-discribe-new-content1 {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.checkromm-tick-new {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.ratePlan-new {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 33px;
}

.priceCheck-new {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: row;
  gap: 24px;
}

.priceCheck-new .second-new {
  display: flex;
/* padding: 12.864px 32.16px; */
justify-content: center;
border-radius: 6.432px;
background: #E73C34;
color: var(--white, #FFF);
/* font-family: Montserrat; */
padding: 12px;
cursor: pointer;
font-size: 16.08px;
font-style: normal;
font-weight: 600;
line-height: normal;
align-items: center;
gap: 16.08px;
flex-shrink: 0;
}

.offer_area-new {
  width: auto;
  height: auto;
  /* border-radius: 9.124px; */
  border: 1px solid #ffe7e7 !important;
  background: #fffbfb !important ;
  border-radius: 20px !important;
  opacity: 1;
  margin: 7px 0px 21px 0px !important;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 22px;
  gap: 15px;
}

.Bus_box {

  opacity: 1;
  padding: 15px;
}

.Bussmal_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.textarea_field {
  width: auto;
  height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 0px 20px;
  border: none;

}



.saleSummaryBus {
  padding: 24px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background: #FFFBFB;
  width: 100%;

}


.fareSummary {
  padding: 16px;
  border-radius: 4px;
  background: #B8CCFF;
  color: #21325D;
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.fareSummary-new {
  padding: 16px;
  border-radius: 4px;
  border-radius: var(--rounded, 4px);
  background: rgba(231, 60, 52, 0.80);
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.publishedTax {
  padding: 16px;
  /* border-radius: 4px; */
  /* background: #E4E4E4; */
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;

}

.publishedTax>div {
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
}

.publishedTax p {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.publishedTax span {
  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.busGrandTotal {
  padding: 16px;
  border-radius: 4px;
  background: rgba(231, 60, 52, 0.80);
  color: #fff;
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.busGrandTotal p {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.busGrandTotal span {
  color: #fff;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pickUpBox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  padding: 32px 16px;
  border-radius: 12px;
  border: 0.5px solid #888;
  background: #FFF;
}

.pickUpBox>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.pickUpBox p {
  color: #000;
  font-family: 'Montserrat';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.pickUpBox span {
  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}